import DatePickerCustom from "components/Common/DatePickerCustom";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { ARR_INDEX_TEXT_DAY_OF_WEEK } from "helpers/constans";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Row, Spinner } from "reactstrap";
import TableContainer from "../../../../components/Common/TableContainer";
import ChartLine from "./ChartLine";
import TooltipCustom from "components/Common/TooltipCustom";
import { useDurationResponses } from "components/Hooks/DurationResponsesHooks";
import LabelWrapper from "components/Common/LabelWrapper";
import SearchFilterMedia from "components/Common/SearchFilterMedia";
import DropdownOption from "components/Common/DropdownOption";
import { IPagination } from "api/types/general";
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync } from "helpers/general";
import cmsHubStatistics from "api/cmsHubStatistics";
registerLocale("en", en);
registerLocale("ko", ko);

const VisitorStatistic = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [rows, setRows] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<any>();

  const companies = useSelector((state: any) => state?.Company?.companies);

  const defaultParams = {
    page: 1,
    page_size: 10,
    start_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    sort_name: "date",
    sort_by: "DESC",
    company_id: "",
  };
  const { getQueryParams, setQueryParams } = useQueryParams(defaultParams);
  const queryParams = getQueryParams();

  const TYPE_COMPANIES_OPTIONS =
    companies?.map((item: any) => ({
      value: item?.id,
      label: item?.name,
    })) || [];

  const [dateSearch, setDateSearch] = useState<any[]>([
    moment(queryParams?.start_date || "", "Y-MM-DD").toDate(),
    moment(queryParams?.end_date || "", "Y-MM-DD").toDate(),
  ]);
  const [startDate, endDate] = dateSearch;

  const searchData = () => {
    setQueryParams({
      ...queryParams,
      company_id: company?.value,
      start_date: startDate
        ? moment(new Date(startDate)).format("Y-MM-DD")
        : defaultParams?.start_date,
      end_date: endDate
        ? moment(new Date(endDate)).format("Y-MM-DD")
        : moment().format("YYYY-MM-DD"),
      page: 1,
    });
  };

  const resetData = () => {
    setQueryParams(defaultParams);
    setDateSearch([
      moment(defaultParams?.start_date || "", "Y-MM-DD").toDate(),
      moment(defaultParams?.end_date || "", "Y-MM-DD").toDate(),
    ]);
    setCompany(null);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Date"),
        accessor: "date",
        filterable: true,
        sortable: true,
        thWidth: 100,
        thClass: "text-start align-middle",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          const num = moment(cell?.value).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
          const classText =
            num === 0
              ? "text-danger row-bg-danger"
              : num === 6
              ? "text-primary row-bg-primary"
              : "";
          const textNote = t(`STANDS_${ARR_INDEX_TEXT_DAY_OF_WEEK[num]}`);
          return (
            <div className="text-start" style={{ minWidth: "100px" }}>
              <span className={classText}>
                {moment(arrDate?.[0] || "", "Y-MM-DD").format("MM-DD")} (
                {textNote})
              </span>
            </div>
          );
        },
      },
      {
        Header: t("PV"),
        accessor: "pv",
        filterable: false,
        sortable: true,
        thClass: "text-end align-middle",
        description: t("Description PV"),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div className="text-end me-3" style={{ minWidth: "60px" }}>
                {cell?.value}
              </div>
            </>
          );
        },
      },
      {
        Header: t("UV"),
        accessor: "uv",
        filterable: false,
        sortable: true,
        thClass: "text-end align-middle",
        description: t("Description UV"),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3" style={{ minWidth: "60px" }}>
              {cell?.value}
            </div>
          </>
        ),
      },
      {
        Header: t("Total News Count"),
        accessor: "total_published",
        filterable: false,
        sortable: true,
        thClass: "text-end align-middle",
        description: t("Description Published news count a day"),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3" style={{ minWidth: "60px" }}>
              {cell?.value}
            </div>
          </>
        ),
      },
      {
        Header: t("None1"),
        thClass: "text-center",
        filterable: false,
        sortable: false,
        thWidth: 50,
        thComponent: () => <></>,
      },
      {
        Header: t("PV Platform"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        // description: t('Description PV field each platform'),
        thWidth: 300,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1" colSpan={3}>
                  {t("PV Platform")}
                  <TooltipCustom
                    title={t("Description PV field each platform")}
                    className="d-inline-block vertical-align-middle ms-1"
                    style={{ transform: "translateY(2px)" }}
                  >
                    <i className="ri-question-line align-bottom text-secondary"></i>
                  </TooltipCustom>
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1"
                  style={{ width: "100px" }}
                >
                  {t("Visitor Naver")}
                </th>
                <th
                  className="text-center px-0 py-1"
                  style={{ width: "100px" }}
                >
                  {t("Visitor Google")}
                </th>
                <th
                  className="text-center px-0 py-1"
                  style={{ width: "100px" }}
                >
                  {t("Visitor Etc")}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>{item.pv_naver}</div>
              <div
                style={{
                  width: "100px",
                  borderLeft: "1px solid #eaeaea",
                  borderRight: "1px solid #eaeaea",
                }}
              >
                {item.pv_google}
              </div>
              <div style={{ width: "100px" }}>{item.pv_etc}</div>
            </div>
          );
        },
      },
      {
        Header: t("None2"),
        thClass: "text-center",
        filterable: false,
        sortable: false,
        thWidth: 50,
        thComponent: () => <></>,
      },
      {
        Header: t("Referrer Rate"),
        thClass: "text-center",
        filterable: false,
        sortable: false,
        // description: t('Referrer Rate'),
        thWidth: 300,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1" colSpan={3}>
                  {t("Referrer Rate")}
                  <TooltipCustom
                    title={t("Referrer Rate")}
                    className="d-inline-block vertical-align-middle ms-1"
                    style={{ transform: "translateY(2px)" }}
                  >
                    <i className="ri-question-line align-bottom text-secondary"></i>
                  </TooltipCustom>
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1"
                  style={{ width: "100px" }}
                >
                  {t("Visitor Naver")}
                </th>
                <th
                  className="text-center px-0 py-1"
                  style={{ width: "100px" }}
                >
                  {t("Visitor Google")}
                </th>
                <th
                  className="text-center px-0 py-1"
                  style={{ width: "100px" }}
                >
                  {t("Visitor Etc")}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>{item.percent_pv_naver}%</div>
              <div
                style={{
                  width: "100px",
                  borderLeft: "1px solid #eaeaea",
                  borderRight: "1px solid #eaeaea",
                }}
              >
                {item.percent_pv_google}%
              </div>
              <div style={{ width: "100px" }}>{item.percent_pv_etc}%</div>
            </div>
          );
        },
      },
      // {
      //   Header: t('UV (Yesterday)'),
      //   accessor: "uv_yesterday",
      //   filterable: false,
      //   sortable: true,
      //   thClass: 'text-end',
      //   description: t('Description UV (Yesterday)'),
      //   Cell: (cell: any) => (
      //     <>
      //       <div className="text-end me-3">{cell?.value}</div>
      //     </>
      //   ),
      // },
      // {
      //   Header: t('UV (2 Days ago)'),
      //   accessor: "uv_2days_ago",
      //   filterable: false,
      //   sortable: true,
      //   thClass: 'text-end',
      //   description: t('Description UV of news which were published between 2 and 7 days ago'),
      //   Cell: (cell: any) => (
      //     <>
      //       <div className="text-end me-3">{cell?.value}</div>
      //     </>
      //   ),
      // },
      {
        Header: t("UV (8 Days ago)"),
        accessor: "uv_8_days_before",
        filterable: false,
        sortable: true,
        thClass: "text-end align-middle",
        description: t(
          "Description UV of news which were published 8 days before"
        ),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3" style={{ minWidth: "120px" }}>
              {cell?.value}
            </div>
          </>
        ),
      },
    ],
    [i18n?.language]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const series = useMemo(() => {
    // const obChart =
    //   rows?.reduce(
    //     (ob: any, item: any) => {
    //       ob?.pv?.push(item?.pv);
    //       ob?.uv?.push(item?.uv);
    //       return ob;
    //     },
    //     { pv: [], uv: [], news: [] }
    //   ) || [];
    // console.log("obChart", obChart);

    // return [
    //   {
    //     name: t("UV"),
    //     type: "line",
    //     data: obChart?.uv?.reverse(),
    //   },
    //   {
    //     name: t("News"),
    //     type: "column",
    //     data: obChart?.news?.reverse(),
    //   },
    // ];
    return [
      {
        name: t("UV"),
        type: "line",
        data: rows?.map((i) => i?.uv),
      },
      {
        name: t("News"),
        type: "column",
        data: rows?.map((i) => i?.total_published),
      },
    ];
  }, [rows, i18n?.language]);

  useEffect(() => {
    // document.title = `Super Admin`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  const paging = async () => {
    setLoading(true);
    const [err, result] = await catchAsync(
      cmsHubStatistics.getVisit(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }
    setRows(result?.data);
  };

  useEffect(() => {
    paging();
  }, [location.search]);

  return (
    <React.Fragment>
      <Row>
        {true && (
          <Col lg={12}>
            <Card id="leadsList" className="ribbon-box right">
              <div
                className="ribbon round-shape"
                style={{
                  background: "#1548a0",
                  fontWeight: 300,
                  fontSize: "0.8rem",
                }}
              >
                {t("This data is analyzed per 10 mins")}
              </div>
              <CardHeader className="border-0" style={{ borderRadius: "30px" }}>
                <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                  <div className="d-flex mb-4 mb-md-0 flex-column flex-sm-row">
                    <Card
                      className="card-animate mb-0 me-4 me-md-4 mt-3 bg-primary-subtle text-primary border-0"
                      style={{ width: "200px" }}
                    >
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("Total")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                              <span className="counter-value text-primary">
                                {loading ? (
                                  <Spinner size="sm"></Spinner>
                                ) : (
                                  <CountUp
                                    start={0}
                                    end={rows?.length || 0}
                                    duration={1}
                                  />
                                )}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card
                      className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0"
                      style={{ width: "200px" }}
                    >
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("Total News")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                              <span className="counter-value text-primary">
                                {loading ? (
                                  <Spinner size="sm"></Spinner>
                                ) : (
                                  <CountUp
                                    start={0}
                                    end={rows?.reduce(
                                      (a, b) => a + b?.total_published,
                                      0
                                    )}
                                    duration={1}
                                  />
                                )}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="w-100">
                    <Row className="g-4 align-items-center mb-2 mt-2">
                      <Col sm={12} md={3} lg={3} className="mt-2">
                        <div>
                          <DropdownOption
                            name="type"
                            dataList={TYPE_COMPANIES_OPTIONS || []}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={company}
                            onChangeSelect={(event: any) => {
                              setCompany(event);
                            }}
                            isHasOptionAll={true}
                            optionAll={{
                              label: t("All"),
                              value: "",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-4 align-items-center mt-0">
                      {/* <Col sm={6} md={3} lg={3} className="mt-2">
                        <LabelWrapper label={t("News Type")} isShow={true}>
                          <DropdownOption
                            name=""
                            dataList={TYPE_COMPANIES_OPTIONS?.filter((item) =>
                              ["0", "1"]?.includes(item?.value)
                            )}
                            placeholder={`${t("News Type")}...`}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={typeNewsSearch || null}
                            onChangeSelect={(e: any) => setTypeNewsSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Data"), value: "" }}
                          />
                        </LabelWrapper>
                      </Col> */}
                      <Col
                        sm={6}
                        md={3}
                        lg={3}
                        className="date-picker-wrapper-custom mt-2"
                      >
                        <LabelWrapper
                          label={t("Date")}
                          isShow={!!startDate || !!endDate}
                        >
                          <DatePickerCustom
                            placeholder={`${t("Start Date")} - ${t(
                              "End Date"
                            )}`}
                            startDate={startDate || null}
                            endDate={endDate || null}
                            onChangePicker={handleChangePicker}
                            showOptions={[
                              "today",
                              "yesterday",
                              "last_3_days",
                              "last_7_days",
                              "last_14_days",
                              "last_30_days",
                              "this_week",
                              "last_week",
                              "this_month",
                              "last_month",
                            ]}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col
                        sm={12}
                        md={6}
                        lg={6}
                        className="hstack gap-1 justify-content-sm-start justify-content-md-start mt-2"
                      >
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                            disabled={loading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t("Button Search")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t("Button Reset")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <ChartLine
                  className="mb-5"
                  titles={[t("Visitor"), t("Number of articles")]}
                  categories={rows?.map((i) => moment(i?.date).format("MM-DD"))}
                  series={series}
                />
                <div>
                  <TableContainer
                    columns={columns}
                    data={rows || []}
                    isShowPagination={false}
                    className="custom-header-css"
                    divClass="table-responsive table-card"
                    tableClass="align-middle"
                    theadClass="table-light align-middle"
                    isLoading={loading}
                    sorting={{
                      sort_by: "date",
                      order_by: queryParams?.sort_by
                        ? queryParams?.sort_by + ""
                        : "DESC",
                    }}
                    handleChangeSorting={(event) => {
                      setQueryParams({
                        ...queryParams,
                        sort_by: event?.order_by,
                      });
                    }}
                    scrollbarOs={false}
                  />
                </div>
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default VisitorStatistic;
