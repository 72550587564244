import { useState } from "react";
import { useTranslation } from "react-i18next";
import { catchAsync } from "helpers/general";
import pointApi from "api/pointApi";
import ModalConfirm from "./ModalConfirm";

function GrantedDeductedPoint(props: any) {
  const { id, serviceType, refresh } = props;
  const { t } = useTranslation();
  const [deductPoints, setDeductPoints] = useState<string | number>("0");
  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState<any>(false);
  const [confirmData, setConfirmData] = useState<any>({});

  const updatePoint = async (type: string) => {
    setLoading(true);
    try {
      const [err, result] = await catchAsync(
        pointApi.updateSinglePoint({
          id: id,
          type: type,
          data: {
            service_type: serviceType, // CMS_HUB: 1, PR_HUB: 2, NEWS_HUB: 3
            point: +deductPoints,
          },
        })
      );

      console.log("result", result);

      if (result?.statusCode === 200) {
        // setData(result?.data?.items || []);
        refresh();
      }
      if (err) {
        console.log(err);
        return;
      }
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setLoading(false);
      setDeductPoints(0);
      setOpenConfirm(false);
    }
  };

  return (
    <>
      <div className='d-flex gap-2 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2'>
        <div
          className='position-relative'
          style={{
            width: "80px",
          }}
        >
          <input
            autoFocus
            className='form-control'
            id='deduction_point'
            name='deduction_point'
            value={deductPoints}
            onChange={(event: any) => {
              if (/^[0-9]*$/.test(event?.target?.value) || event?.target?.value === "") {
                let value = event?.target?.value;
                if (value.charAt(0) === "0") {
                  value = value.substring(1);
                }
                setDeductPoints(value);
              }
            }}
            style={{
              textAlign: "right",
              paddingRight: "25px",
            }}
            maxLength={9}
          />
          <p
            className='position-absolute'
            style={{
              top: 8,
              right: 10,
            }}
          >
            p
          </p>
        </div>
        <button
          type='button'
          disabled={loading}
          className='btn btn-primary'
          onClick={() => {
            setConfirmData({
              content: "Award_Points",
              point: deductPoints,
              type: "add",
            });
            setOpenConfirm(true);
          }}
        >
          {t("media.Gift")}
        </button>
        <button
          type='button'
          disabled={loading}
          className='btn btn-secondary fs-14'
          onClick={() => {
            setConfirmData({
              content: "Deduction_Points",
              point: deductPoints,
              type: "subtract",
            });
            setOpenConfirm(true);
          }}
        >
          {t("media.Deduct")}
        </button>
      </div>
      <ModalConfirm
        header={""}
        isOpen={!!openConfirm}
        isShowIcon={false}
        isLoading={loading}
        title={t(`media.Check`)}
        content={t(`media.${confirmData?.content}`, { point: confirmData?.point })}
        textButtonConfirm={t("media.Check")}
        classButtonConfirm='btn-primary'
        classIconButtonConfirm=''
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => updatePoint(confirmData?.type)}
      />
    </>
  );
}

export default GrantedDeductedPoint;
