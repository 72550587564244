import { useEffect, useState } from "react";
import { Progress, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
//
import importApi from "api/importApi";
import { catchAsync } from "helpers/general";
import { toast } from "react-toastify";
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import Loader from "components/Common/Loader";

interface Props {
  nextImport: any;
  nextStep:()=>void;
}
const ImportData = (props: Props) => {
  const {t} = useTranslation();
  const [data, setData] = useState<any>(null);
  const getDataSqlImport = async () => {
    const [err, result] = await catchAsync(
      importApi.dumpSqlImportProcessStatus(
        props.nextImport.partner_name,
        props.nextImport.job_id
      )
    );
    if (err) {
      console.log(err);
      toast(err?.message || err, CONFIG_OPTION_TOAST_ERROR);
      return;
    }
    result?.data && setData(result?.data);
  };

  useEffect(() => {
    if (!props?.nextImport?.partner_name || !props?.nextImport?.job_id) return;
    getDataSqlImport();

    const interval = setInterval(() => {
      getDataSqlImport();
    }, 3000);

    if (data?.is_complete || !data) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [
    props?.nextImport?.partner_name,
    props?.nextImport?.job_id,
    data?.is_complete,
  ]);

  return (
    <div className="d-flex flex-column gap-2" style={{width: "70%",margin:"0 auto"}}>
      {data ? (
        <>
          {Object.keys(data.steps)?.map((item: any, index: number) => {
            return (
              <div
                key={index}
                style={{ border: "1px solid #eaeaea", padding: "10px",borderRadius:'4px' }}
              >
                <div className="d-flex align-items-center gap-2">
                  {data.steps[item]?.status === "done" ? (
                    <i  
                      className=" bx bxs-check-circle"
                      style={{ fontSize: 36, color: "#25a0e2" }}
                    ></i>
                  ) : (
                    <></>
                  )}

                  {data.steps[item]?.status === "running" ? (
                    <Spinner size={"md"} style={{ color: "#17B78F" }} />
                  ) : (
                    <></>
                  )}
                  <div className="d-flex flex-column gap-1 w-100">
                    <div className="d-flex align-items-center w-100">
                      <b>{item}</b>
                      {/* <span>{data.steps[item].lastMessage}</span> */}
                    </div>
                      <Progress
                        style={{
                          height: '5px',
                          width:'100%'
                        }}
                        value={data.steps[item]?.status === "done" ? 100 : data.steps[item]?.percent}
                     />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div>
        <div className="d-flex justify-content-center flex-column align-items-center gap-2" style={{ height: "400px" }}>
          <Loader></Loader>
          <div>{t("cms_media.loading_data")}</div>
        </div>
      </div>
      )}
      <div className="d-flex gap-2 justify-content-center mb-5 mt-4">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={props.nextStep}
          disabled={!data?.is_complete}
        >
          {t("cms_media.complete")}
        </button>
      </div>
    </div>
  );
};

export default ImportData;
