import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
//
import { authResources } from "./language/auth/i18n";
import { generalResources } from "./language/general/i18n";
import { mediaResources } from "./language/media/i18n";
import { cmsMediaResources } from "./language/cms/i18n";
import { accountResources } from "./language/account/i18n";
import { membershipResources } from "./language/membership/i18n";
import { articleManagementResources } from "./language/articleManagement/i18n";
import { inquiryResources } from "./language/inquiry/i18n";
import { transferResources } from "./language/transfer/i18n";

interface TranslationResource {
  [key: string]: any;
}

interface LanguageResources {
  ko: TranslationResource;
  en: TranslationResource;
}

const deepMerge = (
  target: TranslationResource,
  source: TranslationResource
): TranslationResource => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object && key in target) {
      Object.assign(source[key], deepMerge(target[key], source[key]));
    }
  }
  return { ...target, ...source };
};

const mergeResources = (modules: LanguageResources[]): LanguageResources => {
  return modules.reduce(
    (acc, module) => {
      return {
        ko: deepMerge(acc.ko || {}, module.ko),
        en: deepMerge(acc.en || {}, module.en),
      };
    },
    { ko: {}, en: {} } as LanguageResources
  );
};

const modules: LanguageResources[] = [
  generalResources,
  mediaResources,
  authResources,
  cmsMediaResources,
  accountResources,
  articleManagementResources,
  membershipResources,
  inquiryResources,
  transferResources,
];
const resources: any = mergeResources(modules);

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "ko");
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "ko",
    fallbackLng: "ko", // use en if detected lng is not available

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
