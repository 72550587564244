import { IMedia } from "api/types/_media";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IPagination } from "api/types/general";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../../../components/Common/BreadCrumb";
import TableContainer from "../../../../components/Common/TableContainer";
import {
  getAllMedias,
  getWebsiteLogsByNormal,
  getWebsiteSummariesLogs,
} from "../../../../store/thunks";

import { IPreviewNews } from "api/types/_news";
import { IWebsiteLog } from "api/types/_statistic";
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import SearchFilterMedia from "components/Common/SearchFilterMedia";
import TooltipCustom from "components/Common/TooltipCustom";
import { useDurationResponses } from "components/Hooks/DurationResponsesHooks";
import { useRole } from "components/Hooks/UserHooks";
import {
  BOT_OPTIONS,
  BROWSER_OPTIONS,
  DEVICE_OPTIONS,
  IS_OPTIONS,
  OS_OPTIONS,
  PLATFORM_LOG_OPTIONS,
  TYPE_NEWS_OPTIONS,
} from "helpers/constans";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import CountUp from "react-countup";
import { renderUrlSearchLookingUpByIP } from "helpers/format";
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync } from "helpers/general";
import cmsHubStatistics from "api/cmsHubStatistics";
import MediaApi from "api/mediaApi";
import { useSelector } from "react-redux";
interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "createdAt";
const ORDER_BY_DEFAULT = "DESC";

const ArticleLog = () => {
  const { t, i18n } = useTranslation();

  const defaultParams = {
    page: 1,
    page_size: 10,
    title: "",
    uuid: "",
    reference_link: "",
    ip: "",
    og_url: "",
    keyword: "",
    company_id: "",
    platform: "",
    device: "",
    bot: "",
    browser: "",
    os: "",
    is_newshub: "",
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    sort_by: TYPE_SELECT_DEFAULT,
    time_request: +new Date(),
  };
  const companies = useSelector((state: any) => state?.Company?.companies);
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const location = useLocation();
  const [rows, setRows] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [loading, setLoading] = useState(false);
  const [loadingSummaries, setLoadingSummaries] = useState(false);
  const [summaries, setSummaries] = useState<any>();
  const PLATFORM_LOG_OPTIONS_LANG =
    PLATFORM_LOG_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const DEVICE_OPTIONS_LANG =
    DEVICE_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const BOT_OPTIONS_LANG =
    BOT_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const BROWSER_OPTIONS_LANG =
    BROWSER_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const OS_OPTIONS_LANG =
    OS_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [dateSearch, setDateSearch] = useState<any[]>([
    moment(defaultParams?.start_date || "", "Y-MM-DD").toDate(),
    moment(defaultParams?.end_date || "", "Y-MM-DD").toDate(),
  ]);
  const [startDate, endDate] = dateSearch;

  const [referenceLinkSearch, setReferenceLinkSearch] = useState<string>(
    queryParams?.reference_link + "" || ""
  );
  const [ipSearch, setIpSearch] = useState<string>(queryParams?.ip + "" || "");

  const [urlSearch, setUrlSearch] = useState<string>(
    queryParams?.og_url + "" || ""
  );

  const [keywordSearch, setKeywordSearch] = useState<string>(
    queryParams?.keyword + "" || ""
  );

  const [titleSearch, setTitleSearch] = useState<string>(
    queryParams?.title + "" || ""
  );

  const [uuidSearch, setUUIDSearch] = useState<string>(
    queryParams?.uuid + "" || ""
  );

  const listMedia = [
    {
      name: t("Select Media"),
      id: "",
    },
    ...companies,
  ];

  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label:
      listMedia?.filter(
        (item: any) =>
          String(item?.id || "") === String(queryParams?.company_id || "")
      )[0]?.name || t("All Media"),
    value: `${
      listMedia?.filter(
        (item: any) =>
          String(item?.id || "") === String(queryParams?.company_id || "")
      )[0]?.id || ""
    }`,
  });

  const [platformSearch, setPlatformSearch] = useState<Option | null>(
    PLATFORM_LOG_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(queryParams?.platform)
    )[0]
  );

  const [deviceSearch, setDeviceSearch] = useState<Option | null>(
    DEVICE_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(queryParams?.device)
    )[0]
  );

  const [botSearch, setBotSearch] = useState<Option | null>(
    BOT_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(queryParams?.bot)
    )[0]
  );

  const [browserSearch, setBrowserSearch] = useState<Option | null>(
    BROWSER_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(queryParams?.browser)
    )[0]
  );

  const [osSearch, setOsSearch] = useState<Option | null>(
    OS_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(queryParams?.os)
    )[0]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      // search: keyword,
      page: page + 1,
    });
  };

  const resetData = () => {
    const queryNew = {
      ...queryParams,
      title: "",
      uuid: "",
      reference_link: "",
      ip: "",
      og_url: "",
      keyword: "",
      company_id: "",
      sort_by: TYPE_SELECT_DEFAULT,
      order_by: ORDER_BY_DEFAULT,
      start_date: moment(defaultParams?.start_date).format("Y-MM-DD"),
      end_date: moment(defaultParams?.end_date).format("Y-MM-DD"),
      platform: "",
      device: "",
      bot: "0",
      browser: "",
      os: "",
      is_newshub: "",
      page: 1,
      time_request: +new Date(),
    };

    setQueryParams(queryNew);
    setReferenceLinkSearch((_prev) => "");
    setIpSearch((_prev) => "");
    setUrlSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setTitleSearch((_prev) => "");
    setUUIDSearch((_prev) => "");
    setMediaSearch({ label: t("All Media"), value: "" });
    setDateSearch([
      moment(defaultParams?.start_date || "", "Y-MM-DD").toDate(),
      moment(defaultParams?.end_date || "", "Y-MM-DD").toDate(),
    ]);
    setPlatformSearch(null);
    setDeviceSearch(null);
    setBotSearch({
      label: t("All Type Bot"),
      value: "",
    });
    setBrowserSearch(null);
    setOsSearch(null);
  };

  const searchData = () => {
    setQueryParams({
      ...queryParams,
      title: titleSearch || "",
      uuid: uuidSearch || "",
      reference_link: encodeURIComponent(referenceLinkSearch) || "",
      ip: ipSearch || "",
      og_url: encodeURIComponent(urlSearch) || "",
      keyword: keywordSearch || "",
      company_id: mediaSearch?.value
        ? listMedia?.filter(
            (item: any) => Number(item?.id) === Number(mediaSearch?.value)
          )[0]?.website || mediaSearch?.value
        : "",
      platform: platformSearch?.value || "",
      device: deviceSearch?.value || "",
      bot: botSearch?.value ?? "",
      browser: browserSearch?.value ?? "",
      os: osSearch?.value ?? "",
      start_date: startDate
        ? moment(new Date(startDate)).format("Y-MM-DD")
        : moment().subtract(1, "year").format("Y-MM-DD"),
      end_date: endDate
        ? moment(new Date(endDate)).format("Y-MM-DD")
        : moment().format("Y-MM-DD"),
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    });
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Title Post"),
        accessor: "title",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div style={{ maxWidth: "30dvw" }}>
                <CopyWrapper contentCopy={item?.og_title}>
                  <Link
                    className="text-normal"
                    to={item?.og_url}
                    target="_blank"
                  >
                    {item?.og_title}
                  </Link>
                </CopyWrapper>
                <div>
                  <span className="text-dark">{t("Url")}: </span>
                  <CopyWrapper contentCopy={item?.company_id}>
                    <Link
                      className="text-normal"
                      to={item?.company_id}
                      target="_blank"
                    >
                      {item?.company_id}
                    </Link>
                  </CopyWrapper>
                </div>
                <div>
                  <span className="text-dark">{t("WEB_reference_link")}: </span>
                  <CopyWrapper contentCopy={item?.reference_link || ""}>
                    <Link
                      className="text-normal "
                      to={item?.reference_link}
                      target="_blank"
                    >
                      {item?.reference_link || ""}
                    </Link>
                  </CopyWrapper>
                </div>
                <div>
                  <span className="text-dark">{t("UUID")}: </span>
                  <CopyWrapper contentCopy={item?.uuid || ""}>
                    {item?.uuid || ""}
                  </CopyWrapper>
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: t("Keyword"),
        accessor: "keyword",
        filterable: true,
        sortable: false,
        thWidth: 160,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <CopyWrapper
                contentCopy={cell?.value}
                style={{ minWidth: "140px" }}
              >
                {cell?.value}
              </CopyWrapper>
            </>
          );
        },
      },
      {
        Header: t("Media"),
        accessor: "domain",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="cursor-pointer" style={{ minWidth: "130px" }}>
                {cell?.value && (
                  <>
                    {/* <span>
                      {listMedia?.filter(
                        (item: any) =>
                          String(item?.website) === String(cell?.value)
                      )[0]?.name || ""}
                    </span>
                    <br /> */}
                    <Link
                      className="text-normal"
                      to={
                        String(cell?.value).includes("https://") ||
                        String(cell?.value).includes("http://")
                          ? cell?.value
                          : `https://${cell?.value}`
                      }
                      target="_blank"
                    >
                      {cell?.value}
                    </Link>
                  </>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Platform"),
        accessor: "platform",
        filterable: true,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => (
          <div style={{ minWidth: "80px" }}>
            <span>
              {PLATFORM_LOG_OPTIONS_LANG?.filter(
                (item) =>
                  !!cell?.value && String(item?.value) === String(cell?.value)
              )[0]?.label || cell?.value}
            </span>
          </div>
        ),
      },
      {
        Header: t("Device"),
        accessor: "device",
        filterable: true,
        sortable: false,
        thWidth: 90,
        Cell: (cell: any) => (
          <div style={{ minWidth: "80px" }}>
            <span>
              {DEVICE_OPTIONS_LANG?.filter(
                (item) =>
                  !!cell?.value && String(item?.value) === String(cell?.value)
              )[0]?.label || cell?.value}
            </span>
          </div>
        ),
      },
      {
        Header: t("OS"),
        accessor: "os",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <div style={{ minWidth: "80px" }}>
            <span>{cell?.value}</span>
          </div>
        ),
      },
      {
        Header: t("Browser"),
        accessor: "browser",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <div style={{ minWidth: "80px" }}>
            <span>{cell?.value}</span>
          </div>
        ),
      },
      {
        Header: t("IP"),
        accessor: "ip",
        filterable: true,
        sortable: false,
        thWidth: 130,
        Cell: (cell: any) => (
          <div style={{ minWidth: "120px" }}>
            <CopyWrapper contentCopy={cell?.value}>
              <Link
                className="text-normal flex-shrink-0 "
                to={renderUrlSearchLookingUpByIP(cell?.value)}
                target="_blank"
              >
                {cell?.value}
              </Link>
            </CopyWrapper>
          </div>
        ),
      },
      {
        Header: t("WEB_is_newshub"),
        accessor: "is_newshub",
        filterable: true,
        sortable: false,
        thWidth: 140,
        thClass: "text-center",
        Cell: (cell: any) => (
          <div style={{ minWidth: "80px" }}>
            <div className="text-center">{t(`Type_News_${cell?.value}`)}</div>
          </div>
        ),
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        filterable: true,
        sortable: true,
        thWidth: 120,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <div className="text-end" style={{ minWidth: "100px" }}>
              <span>
                <span>{String(cell?.value || "").split("T")[0] || ""}</span>{" "}
                <br />{" "}
                <span className="text-secondary">
                  {String(String(cell?.value || "").split("T")[1]).split(
                    "."
                  )[0] || ""}
                </span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Button Action"),
        thClass: "text-center",
        thWidth: 60,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
              <TooltipCustom
                title={t("Detail Media Log")}
                id={`detail-log-${item?.id}`}
              >
                <li
                  className=" list-inline-item me-0"
                  id={`detail-log-${item?.id}`}
                >
                  <Link
                    className="btn btn-sm btn-soft-secondary edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      // handlePreviewWebsiteLog(item);
                    }}
                  >
                    <i className="ri-eye-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>
            </ul>
          );
        },
      },
    ],
    [i18n?.language, listMedia]
  );

  const paging = async () => {
    setLoading(true);
    const [err, result] = await catchAsync(
      cmsHubStatistics.getArticlesLogs(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }

    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  const getSummaries = async () => {
    setLoadingSummaries(true);
    const [err, result] = await catchAsync(
      cmsHubStatistics.getArticlesSummaries(queryParams),
      setLoadingSummaries
    );

    if (err) {
      console.log(err);
      return;
    }
    setLoadingSummaries(false);
    setSummaries(result?.data);
  };

  useEffect(() => {
    paging();
    getSummaries();
  }, [location.search]);

  useEffect(() => {
    // document.title = `Super Admin`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <Row>
        {true && (
          <Col lg={12}>
            <Card id="leadsList">
              <CardHeader className="border-0">
                <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-start mb-2 mt-2">
                  <div className="d-flex w-100 flex-wrap gap-3 gap-md-2 menu-card-statistics mt-0 mt-md-3">
                    <Card className="card-animate mb-0 me-0 me-md-4 mb-0 mb-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("Total")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                              <span className="counter-value text-primary">
                                {loading ? (
                                  <Spinner size="sm"></Spinner>
                                ) : (
                                  <CountUp
                                    start={0}
                                    end={pagination?.total || 0}
                                    duration={1}
                                  />
                                )}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="card-animate mb-0 me-0 me-md-4 mb-0 mb-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("UV")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                              <span className="counter-value text-primary">
                                {loadingSummaries ? (
                                  <Spinner size="sm"></Spinner>
                                ) : (
                                  <CountUp
                                    start={0}
                                    end={summaries?.uv || 0}
                                    duration={1}
                                  />
                                )}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="card-animate mb-0 me-0 me-md-4 mt-0 mt-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("Keyword PV")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                              <span className="counter-value text-primary">
                                {loadingSummaries ? (
                                  <Spinner size="sm"></Spinner>
                                ) : (
                                  <CountUp
                                    start={0}
                                    end={summaries?.pv_keyword || 0}
                                    duration={1}
                                  />
                                )}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="card-animate mb-0 me-0 me-md-4 mt-0 mt-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("Keyword UV")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                              <span className="counter-value text-primary">
                                {loadingSummaries ? (
                                  <Spinner size="sm"></Spinner>
                                ) : (
                                  <CountUp
                                    start={0}
                                    end={summaries?.uv_keyword || 0}
                                    duration={1}
                                  />
                                )}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="card-animate mb-0 me-0 me-md-4 mt-0 mt-md-2 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("UV (UUID)")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                              <span className="counter-value text-primary">
                                {loadingSummaries ? (
                                  <Spinner size="sm"></Spinner>
                                ) : (
                                  <CountUp
                                    start={0}
                                    end={summaries?.uv_uuid || 0}
                                    duration={1}
                                  />
                                )}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="w-100">
                    <Row className="g-4 align-items-center mb-2 mt-2">
                      <Col sm={12} lg={3} className="mt-3 mt-md-2">
                        <LabelWrapper
                          label={t("Device")}
                          isShow={!!deviceSearch?.value}
                        >
                          <DropdownOption
                            name=""
                            dataList={DEVICE_OPTIONS_LANG}
                            placeholder={`${t("Device")}...`}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={deviceSearch || null}
                            onChangeSelect={(e: any) => setDeviceSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{
                              label: t("All Device"),
                              value: "",
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} lg={3} className="mt-3 mt-md-2">
                        <LabelWrapper
                          label={t("WEB_bot")}
                          isShow={!!botSearch?.value}
                        >
                          <DropdownOption
                            name=""
                            dataList={BOT_OPTIONS_LANG}
                            placeholder={`${t("WEB_bot")}...`}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={botSearch || null}
                            onChangeSelect={(e: any) => setBotSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{
                              label: t("All Type Bot"),
                              value: "",
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} lg={3} className="mt-3 mt-md-2">
                        <LabelWrapper
                          label={t("Platform")}
                          isShow={!!platformSearch?.value}
                        >
                          <DropdownOption
                            name=""
                            dataList={PLATFORM_LOG_OPTIONS_LANG}
                            placeholder={`${t("Platform")}...`}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={platformSearch || null}
                            onChangeSelect={(e: any) => setPlatformSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{
                              label: t("All Platform"),
                              value: "",
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col
                        sm={12}
                        lg={3}
                        className="mt-3 mt-md-2 date-picker-wrapper-custom"
                      >
                        <LabelWrapper
                          label={t("Date")}
                          isShow={!!startDate || !!endDate}
                        >
                          <DatePickerCustom
                            startDate={startDate || null}
                            endDate={endDate || null}
                            onChangePicker={handleChangePicker}
                          />
                        </LabelWrapper>
                      </Col>
                    </Row>
                    <Row className="g-4 align-items-center mb-2 mt-2">
                      <Col sm={12} lg={3} className="mt-2"></Col>

                      <Col sm={12} lg={3} className="mt-3 mt-md-2">
                        <LabelWrapper
                          label={t("WEB_os")}
                          isShow={!!osSearch?.value}
                        >
                          <DropdownOption
                            name=""
                            dataList={OS_OPTIONS_LANG}
                            placeholder={`${t("WEB_os")}...`}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={osSearch || null}
                            onChangeSelect={(e: any) => setOsSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All OS"), value: "" }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col sm={12} lg={3} className="mt-3 mt-md-2">
                        <LabelWrapper
                          label={t("WEB_browser")}
                          isShow={!!browserSearch?.value}
                        >
                          <DropdownOption
                            name=""
                            dataList={BROWSER_OPTIONS_LANG}
                            placeholder={`${t("WEB_browser")}...`}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={browserSearch || null}
                            onChangeSelect={(e: any) => setBrowserSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{
                              label: t("All Browser"),
                              value: "",
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} lg={3} className="mt-3 mt-md-2">
                        <LabelWrapper
                          label={t("Media")}
                          isShow={!!mediaSearch?.value}
                        >
                          <SearchFilterMedia
                            name="medias"
                            isMulti={false}
                            dataList={listMedia}
                            initialValue={mediaSearch}
                            onChangeSelect={(event: any) => {
                              setMediaSearch((_prev) => event);
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                    </Row>
                    <Row className="g-4 align-items-center mb-2 mt-2">
                      <Col sm={12} lg={3} className="mt-2">
                        <LabelWrapper label={t("IP")} isShow={!!ipSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("IP")}...`}
                            value={ipSearch}
                            onChange={(e) => setIpSearch(e.target.value)}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} lg={3} className="mt-3 mt-md-2">
                        <LabelWrapper label={t("UUID")} isShow={!!uuidSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("UUID")}...`}
                            value={uuidSearch}
                            onChange={(e) => setUUIDSearch(e.target.value)}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} lg={3} className="mt-3 mt-md-2">
                        <LabelWrapper
                          label={t("Keyword")}
                          isShow={!!keywordSearch}
                        >
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Keyword")}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col sm={12} lg={3} className="mt-3 mt-md-2">
                        <LabelWrapper label={t("Url")} isShow={!!urlSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Url")}...`}
                            value={urlSearch}
                            onChange={(e) => setUrlSearch(e.target.value)}
                          />
                        </LabelWrapper>
                      </Col>
                    </Row>
                    <Row className="g-4 align-items-center mb-2 mt-2">
                      <Col sm={12} lg={6} className="mt-2">
                        <LabelWrapper
                          label={t("Title Post")}
                          isShow={!!titleSearch}
                        >
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Title Post")}...`}
                            value={titleSearch}
                            onChange={(e) => setTitleSearch(e.target.value)}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} lg={6} className="mt-3 mt-md-2">
                        <LabelWrapper
                          label={t("WEB_reference_link")}
                          isShow={!!referenceLinkSearch}
                        >
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("WEB_reference_link")}...`}
                            value={referenceLinkSearch}
                            onChange={(e) =>
                              setReferenceLinkSearch(e.target.value)
                            }
                          />
                        </LabelWrapper>
                      </Col>
                      <Col
                        sm={12}
                        lg={12}
                        className="hstack gap-1 justify-content-sm-center justify-content-md-end mt-4 mt-md-3"
                      >
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                            disabled={loading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t("Button Search")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t("Button Reset")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0 px-0">
                <div className="mx-3 my-4">
                  <TableContainer
                    columns={columns}
                    data={rows || []}
                    customPageSize={pagination?.page_size}
                    customPageIndex={pagination?.page - 1}
                    totalRecords={pagination?.total}
                    customPageCount={Math.ceil(
                      pagination?.total / pagination?.page_size
                    )}
                    className="custom-header-css"
                    divClass="table-responsive table-card"
                    tableClass="align-middle"
                    theadClass="table-light align-middle"
                    handleChangePage={handleChangePage}
                    isLoading={loading}
                  />
                </div>
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default ArticleLog;
