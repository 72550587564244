import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
//
import TableContainer from "components/Common/TableContainer";
import { catchAsync } from "helpers/general";
import MediaApi from "api/mediaApi";
import { useModal } from "context/confirm.context";

const GeneralMemberInformation = ({
  id,
  onClose,
  mediaDetailDefault,
  refetch,
  mode
}: {
  id: number;
  onClose: () => void;
  mediaDetailDefault: any;
  refetch: any;
  mode?: "view" | "edit"
}) => {
  const { t } = useTranslation();
  const { showModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [mediaDetail, setMediaDetail] = useState<any>(mediaDetailDefault);
  const [rows, setRows] = useState<Array<any>>([]);
  const [params, setParams] = useState<any>({
    page: 1,
    page_size: 10,
  });
  const [pagination, setPagination] = useState({} as any);
  const columns = useMemo(
    () => [
      {
        Header: t("media.STT"),
        accessor: "id",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <span>
              {pagination?.total -
                pagination?.page_size * (pagination?.page - 1) -
                cell?.row?.index}
            </span>
          </div>
        ),
      },

      {
        Header: t("media.Email"),
        accessor: "email",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">{cell?.value}</p>
          </div>
        ),
      },
      {
        Header: t("media.MemberName"),
        accessor: "name",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">{cell?.value}</p>
          </div>
        ),
      },
      {
        Header: t("media.Nickname"),
        accessor: "nick_name",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">{cell?.value}</p>
          </div>
        ),
      },
      {
        Header: t("media.PhoneNumber"),
        accessor: "phone_number",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">{cell?.value}</p>
          </div>
        ),
      },
    ],
    [pagination]
  );

  const getData = async (id: number) => {
    const [err, result] = await catchAsync(
      MediaApi.getGeneralMembers(id, params),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  const handleSendPassword = async () => {
    const [err, _] = await catchAsync(MediaApi.sendPasswordByEmail(id));
    if (err) {
      toast.error(err.message);
      return;
    }
    refetch && refetch();
  };

  const handleChangePage = (page: any) => {
    setParams({
      ...params,
      page: page + 1,
    });
  };

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [id, params]);

  useEffect(() => {
    setMediaDetail(mediaDetailDefault);
  }, [mediaDetailDefault]);

  return (
    <div className="h-100 d-flex flex-column">
      <div className="d-flex justify-content-end mb-2">
        <button
          type="button"
          className="btn btn-primary fs-14 w-25"
          disabled={(mediaDetail?.migration_status_general_member === 1 ? false : true) || mode === "view"}
          onClick={() => {
            showModal(
              {
                message: (
                  <div>
                    <p>
                      {t("media.SendMailConfirm", {
                        lenght: pagination?.total,
                      })}
                    </p>
                  </div>
                ),
                title: t("media.Guide"),
                submitButtonText: t("account.btn.check"),
                cancelButtonText: t("account.btn.cancel"),
              },
              () => {
                return handleSendPassword();
              }
            );
          }}
        >
          {t("media.TemporaryPasswordSentByEmail")}
        </button>
      </div>
      <div className="flex-fill" style={{ maxHeight: "calc(100% - 47px)" }}>
        <TableContainer
          className="custom-header-css"
          divClass="table-responsive table-card m-0 h-100"
          tableClass="align-middle w-max w-100"
          theadClass="table-light"
          columns={columns}
          data={rows}
          isLoading={loading}
          customPageSize={pagination?.page_size}
          customPageIndex={pagination?.page - 1}
          totalRecords={pagination?.total}
          customPageCount={Math.ceil(pagination?.total / pagination?.page_size)}
          handleChangePage={handleChangePage}
        />
      </div>
      <div className="text-center mt-2">
        <button
          type="button"
          onClick={() => {
            onClose();
          }}
          className="btn btn-primary mx-auto fs-14 w-25"
        >
          {t("media.Check")}
        </button>
      </div>
    </div>
  );
};

export default GeneralMemberInformation;
