import { useCallback, useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
//
import importApi from "api/importApi";
import { catchAsync } from "helpers/general";
import TableContainer from "components/Common/TableContainer";
import { toast } from "react-toastify";
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import Loader from "components/Common/Loader";

interface Props {
  partner_name: string;
  job_id: string;
  company_id: number;
  nextStep: (value: any) => void;
  prevStep: () => void;
}
const Prepare = (props: Props) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const getDataSqlPrepare = async () => {
    const [err, result] = await catchAsync(
      importApi.dumpSqlPrepareStatus(props.partner_name, props.job_id)
    );
    if (err) {
      console.log(err);
      toast(err?.message || err, CONFIG_OPTION_TOAST_ERROR);
      return;
    }
    setData(result?.data);
  };
  const renderDynamicHeader = (headers: any) => {
    return headers?.map((item: any) => ({
      Header: item,
      accessor: item,
      thClass: "text-left",
      Cell: ({ value }: any) => {
        return (
          <div
            className="text-left"
            style={{
              maxWidth: "600px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {value}
          </div>
        );
      },
    }));
  };

  const dynamicTable = useCallback(
    (tableTitle: string, value: any) => {
      return (
        <div>
          <div className="d-flex justify-content-start items-center gap-2 mb-4">
            {value?.status === "done" ? (
              <i
                className=" bx bxs-check-circle"
                style={{ fontSize: 35, color: "#25a0e2" }}
              ></i>
            ) : (
              <></>
            )}

            {value?.status === "running" ? (
              <Spinner className="me-2" style={{ color: "#17B78F" }} />
            ) : (
              <></>
            )}

            {value?.status === "error" ? (
              <i
                className="bx bx-error"
                style={{ fontSize: 35, color: "rgb(237, 29, 36" }}
              ></i>
            ) : (
              <></>
            )}
            <h3 className="text-center">{tableTitle}</h3>
          </div>
          <TableContainer
            columns={renderDynamicHeader(value?.headers)}
            data={value?.reviews || []}
            customPageSize={value?.reviews?.length}
            customPageIndex={value?.reviews?.length}
            totalRecords={value?.reviews?.length}
            customPageCount={1}
            className="custom-header-css"
            divClass="table-responsive table-card h-100"
            tableClass="align-middle"
            theadClass="table-light align-middle"
            isShowPagination={false}
            messageEmpty={t("cms_media.import_inprogress")}
            heightEmpty="300px"
          />
        </div>
      );
    },
    [data]
  );

  const handleImport = async () => {
    const [err, result] = await catchAsync(
      importApi.dumpSqlImportProcess(props.partner_name, props.company_id),
      setLoading
    );
    if (err) {
      console.log(err);
      toast(err?.message || err, CONFIG_OPTION_TOAST_ERROR);
      return;
    }
    props.nextStep({
      partner_name: props.partner_name,
      job_id: result?.data?.id,
    });
  };

  useEffect(() => {
    if (!props.partner_name || !props.job_id) return;
    getDataSqlPrepare();

    const interval = setInterval(() => {
      getDataSqlPrepare();
    }, 3000);

    if (data?.is_complete) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [props.partner_name, props.job_id, data?.is_complete]);

  return (
    <div>
      {data?.steps ? (
        Object.keys(data.steps)?.map((item, index) => {
          return (
            <div key={index} className="mb-4">
              {dynamicTable(item, data.steps[item])}
            </div>
          );
        })
      ) : (
        <div>
          <div className="d-flex justify-content-center flex-column align-items-center gap-2" style={{ height: "400px" }}>
            <Loader></Loader>
            <div>{t("cms_media.loading_data")}</div>
          </div>
        </div>
      )}
      <div className="d-flex gap-2 justify-content-center mb-5 mt-4">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={props.prevStep}
        >
          {t("cms_media.prev")}
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!data?.is_complete || loading}
          onClick={() => handleImport()}
        >
          {loading && <Spinner size="sm" className="me-2" />}
          {t("cms_media.next")}
        </button>
      </div>
    </div>
  );
};
export default Prepare;
