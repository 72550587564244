import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";

// actions
import { loginUser, resetLoginFlag, loginUserWithSSO } from "../../store/thunks";

import { createSelector } from "reselect";
import { StringParam, useQueryParam } from "use-query-params";
import logoDark from "../../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes/allRoutes";
//import images
const md5 = require("md5");

const getDeviceId = () => {
  const userAgent = navigator.userAgent;
  const platform = window.navigator?.platform;
  const randomString =
    Math.random().toString(20).substring(2, 14) + Math.random().toString(20).substring(2, 14);
  const deviceID = md5(`${userAgent}-${platform}-${randomString}`);
  return deviceID;
};
const Login = (props: any) => {
  const { t } = useTranslation();
  const [redirectFrom] = useQueryParam("redirectFrom", StringParam);
  const dispatch = useDispatch<any>();
  const selectLayoutState = (state: any) => state;
  const loginpageData = createSelector(selectLayoutState, (state) => ({
    user: state.Account.user,
    error: state.Login.error,
    errorMsg: state.Login.errorMsg,
  }));
  // Inside your component
  const { error, errorMsg } = useSelector(loginpageData);
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [deviceId, setDeviceId] = useState<string>("");

  const params = new URLSearchParams(window.location.search);
  const ssoRedirect = params.get("sso_redirect");

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
      device_id: deviceId || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("").email(t("auth.InvalidEmailFormatError")),
      password: Yup.string().required(""),
      device_id: Yup.string(),
    }),
    onSubmit: (values) => {
      if (ssoRedirect) {
        dispatch(loginUserWithSSO(values, ssoRedirect));
      } else {
        dispatch(
          loginUser(
            values,
            props.router.navigate,
            redirectFrom ? redirectFrom : "/",
            ROUTES.SET_NEW_PASSWORD
          )
        );
      }
      setLoader(true);
    },
  });

  useEffect(() => {
    let authUser: any = localStorage.getItem("authUser");
    if (authUser && ssoRedirect) {
      let accessToken: any = localStorage.getItem("access_token");
      window.location.href = `${ssoRedirect}?sso_token=${accessToken}`;
    }

    if (authUser && !ssoRedirect) {
      props?.router?.navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectFrom]);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
        setLoader(false);
      }, 1000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    setDeviceId(getDeviceId());
  }, []);

  // document.title = `Super Admin`;

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className='auth-page-content'>
          <Container>
            <Row>
              <Col lg={12}>
                <div className='text-center mt-sm-5 mb-4 text-white-50'>
                  <div>
                    <Link to='/' className='d-inline-block auth-logo'>
                      <img src={logoDark} alt='logo' height='40' />
                    </Link>
                  </div>
                  <p className='mt-3 fs-15 fw-medium'>{t("auth.super_admin_dashboard")}</p>
                </div>
              </Col>
            </Row>

            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card
                  className='mt-4'
                  style={{ boxShadow: "0px 0px 20px 0px rgba(76, 87, 125, 0.02)" }}
                >
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>{t("Welcome Back !")}</h5>
                      <p className='text-muted'>
                        {t("Sign in to continue to Supper Admin System.")}
                      </p>
                    </div>
                    <div className='p-2 mt-4'>
                      {!!error && (
                        <Alert color='danger'>
                          {" "}
                          {error?.errors?.email || error?.errors?.password}{" "}
                        </Alert>
                      )}
                      <Form
                        noValidate
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action='#'
                      >
                        <div className='mb-3'>
                          <Label htmlFor='email' className='form-label'>
                            {t("auth.email.label")}
                          </Label>
                          <Input
                            name='email'
                            className='form-control'
                            placeholder={t("auth.email.placeholder")}
                            type='email'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                            maxLength={50}
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type='invalid'>{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          <Label className='form-label' htmlFor='password-input'>
                            {t("auth.password.label")}
                          </Label>
                          <div className='position-relative auth-pass-inputgroup mb-3'>
                            <Input
                              name='password'
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              className='form-control pe-5'
                              placeholder={t("auth.password.placeholder")}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password
                                  ? true
                                  : false
                              }
                              maxLength={50}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                              onClick={() => setPasswordShow(!passwordShow)}
                              type='button'
                              id='password-addon'
                            >
                              <i className='ri-eye-fill align-middle'></i>
                            </button>
                          </div>
                        </div>
                        <div className='mt-4'>
                          <Button
                            color='success'
                            disabled={loader || !validation.isValid || !validation.dirty}
                            className='btn btn-success w-100'
                            type='submit'
                          >
                            {loader && (
                              <Spinner size='sm' className='me-2'>
                                {t("Loading") + ""}
                              </Spinner>
                            )}
                            {t("auth.btn.login")}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
