import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IPoint } from "./types/_point";
const api = new APIClient();

const path = "/points";
const pointApi = {
  Companies(params: any): Promise<AxiosResponse<ResponseData<IPoint[]> & PaginationResponse, any>> {
    const url = `${path}/companies`;
    const result = api.get(url, params);
    return result;
  },
  updateSinglePoint({
    id,
    type,
    data,
  }: {
    id: any;
    type: string;
    data?: any;
  }): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/companies/${type}/${id}`;
    const result = api.post(url, data);
    return result;
  },
  updateAllPoint({
    type,
    data,
  }: {
    type: string;
    data?: any;
  }): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/companies/${type}-all/`;
    const result = api.post(url, data);
    return result;
  },
};
export default pointApi;
