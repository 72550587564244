import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import React, { useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { useRole } from "components/Hooks/UserHooks";
import {
  TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS,
} from "helpers/constans";
import moment from "moment";
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync } from "helpers/general";
import { IPagination } from "api/types/general";
import MediaApi from "api/mediaApi";
import DatePickerCustom from "components/Common/DatePickerCustom";
import TableContainer from "components/Common/TableContainer";
import MediaModal from "../Media/ModalMedia";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

registerLocale("en", en);
registerLocale("ko", ko);

const MediaDeleteMember = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const location = useLocation();
  const [textMemo, setTextMemo] = useState<string>("");
  const [open, setOpen] = useState(0);
  const [media, setMedia] = useState<any>();

  const [formatSearch, setFormatSearch] = useState<Option | null>({
    label: t("All Type"),
    value: "0",
  });

  const [loading, setLoading] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 10,
  };
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const [keyword, setKeyword] = useState<string>("");
  const [textReply, setTextReply] = useState<string>("");
  const [rows, setRows] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({} as IPagination);

  const [dateSearch, setDateSearch] = useState<string[]>([]);
  const [startDate, endDate] = dateSearch;
  const [isChangeKeyword, setIsChangeKeyword] = useState<boolean>(false);

  const searchData = () => {
    setQueryParams({
      ...queryParams,
      page: 1,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      search: isChangeKeyword ? keyword : queryParams?.search,
    });
  };

  useEffect(() => {
    searchData();
  }, [formatSearch]);

  const columns = useMemo(
    () => [
      {
        Header: t("inquiry.STT"),
        thClass: "text-center",
        Cell: (cell: any) => (
          <div
            className="text-center"
            style={{
              width: "60px",
            }}
          >
            <span>
              {pagination.total -
                pagination.page_size * (pagination.page - 1) -
                cell?.row?.index}
            </span>
          </div>
        ),
      },
      {
        Header: t("cms_media.service"),
        accessor: "services",
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <div className="d-flex gap-2">
              {cell?.value?.map((item: any,index:number) => {
                return (
                <div key={index}>{renderService(item.service_type)}</div>
                );
              })}
            </div>
          );
        },
      },
      {
        Header: t("inquiry.company_name"),
        accessor: "name",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0 cursor-pointer"
            style={{
              minWidth: "100px",
            }}
            onClick={() => setMedia(cell?.row?.original)}
          >
            <u>{cell?.value ? cell?.value : "-"}</u>
          </p>
        ),
      },
      {
        Header: t("cms_media.domain"),
        accessor: "domain",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.value ? cell?.value : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.manager"),
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.row?.original ? cell?.row?.original?.admin?.name : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.email"),
        accessor: "withdrawal_email",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.value ? cell?.value : "-"}
          </p>
        ),
      },
      {
        Header: t("cms_media.phone_number"),
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.row?.original ? cell?.row?.original?.admin?.phone_number : "-"}
          </p>
        ),
      },
      {
        Header: t("cms_media.withdrawal_completion_date"),
        accessor: "withdrawal_at",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "150px",
            }}
          >
            {moment(cell.value).isValid()
              ? moment(cell.value).format("YYYY.MM.DD HH:mm:ss")
              : "-"}
          </p>
        ),
      },
      {
        Header: t("cms_media.reason_for_withdrawal"),
        accessor: "withdrawal_reason",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center cursor-pointer">
              {
              cell?.row?.original?.withdrawal_reason ? (
                <u onClick={()=>{
                  setOpen(1);
                  setTextMemo(cell?.value);
                }}>{t('cms_media.view_reason')}</u>
              ) : "-"
            }
          </div>
        ),
      },
      {
        Header: "",
        accessor: "check",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center cursor-pointer" onClick={() => handleCompleteWithdrawal(cell?.row?.original?.id)}>
            <button
                className="btn btn-soft-secondary fs-14"
                type="button"
                color="light"
              >
                {t("media.Check")}
              </button>
          </div>
        ),
      },
    ],
    [i18n?.language, userPermissions, pagination]
  );

  const paging = async () => {
    const requestBody = {
      ...queryParams,
    };
    const [err, result] = await catchAsync(
      MediaApi.companiesWithdrawal(requestBody),
      setLoading
    );

    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

 const handleCompleteWithdrawal = async (id:number) => {
    const [err, ] = await catchAsync(
      MediaApi.companiesWithdrawalComplete(id),
      setLoading
    );

    if (err) {
      toast.error(err?.message || err);
      return;
    }
    paging();
  };

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      page: page + 1,
    });
  };

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setFormatSearch({
      label: t("All Type"),
      value: "0",
    });
    setDateSearch([]);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const renderService = (service_type: number) => {
    let tagName: string = "";
    const servicesKeyType = new Map(
      TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS.map((i) => [i.value, i.label])
    );
    tagName += `${`<div class='tag-service bg-${servicesKeyType.get(
      service_type.toString()
    )}'  >${
      servicesKeyType.get(service_type.toString()) === "Presscon"
        ? "Prc"
        : servicesKeyType.get(service_type.toString())
    } </div>`}`;
    return (
      <div
        className="mx-auto"
        style={{
          width: "fit-content",
        }}
        dangerouslySetInnerHTML={{ __html: tagName }}
      />
    );
  };

  useEffect(() => {
    paging();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location?.search]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="d-flex align-items-center gap-2 mb-3">
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          >
            <i className={"bx bx-arrow-back fs-22"}></i>
          </button>
          <h4 className="mb-sm-0">
            {t("cms_media.confirm_membership_withdrawal")}
          </h4>
        </div>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2">
                    <div className="w-100">
                      <div className="align-items-end">
                        <Row>
                          <Col
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            className="mt-2 mt-md-0 mb-2 mb-md-0"
                          >
                            <div
                              className="d-flex align-items-center date-picker-wrapper-custom"
                              style={{ gap: "40px" }}
                            >
                              <div className="fw-bold text-nowrap">
                                {t("inquiry.period")}
                              </div>
                              <div style={{ width: "300px" }}>
                                <DatePickerCustom
                                  placeholder={`${t("A From Range")} - ${t(
                                    "A To Range"
                                  )}`}
                                  startDate={startDate || null}
                                  endDate={endDate || null}
                                  disabled={false}
                                  isClearable={true}
                                  onChangePicker={handleChangePicker}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mt-0 mt-md-2">
                          <Col sm={12} md={12} lg={4} className="mt-3 mt-md-2">
                            <div
                              className="d-flex align-items-center "
                              style={{ gap: "40px" }}
                            >
                              <div className="fw-bold text-nowrap">
                                {t("inquiry.search")}
                              </div>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t(
                                  "inquiry.PlaceholderSearch"
                                )}`}
                                value={keyword}
                                onChange={(e) => {
                                  setIsChangeKeyword(true);
                                  setKeyword(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          <Col
                            sm={12}
                            md={12}
                            lg={3}
                            className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2"
                          >
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={loading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t("Button Search")}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t("Button Reset")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle text-center"
                      theadClass="table-light text-center"
                      columns={columns}
                      data={rows || []}
                      customPageSize={pagination?.page_size}
                      customPageIndex={pagination?.page - 1}
                      totalRecords={pagination?.total}
                      customPageCount={Math.ceil(
                        pagination?.total / pagination?.page_size
                      )}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      isLoading={loading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={!!open} centered={true} scrollable={false}>
        <ModalHeader className="justify-content-center">
          {t("media.ReasonForWithdrawal")}
        </ModalHeader>
        <ModalBody className="">
          <div>
            <textarea
              className="form-control"
              id="textMemo"
              rows={5}
              placeholder={t("media.PlaceholderMemo")}
              value={textMemo}
              maxLength={500}
              onChange={(e: any) => setTextMemo(e.target.value)}
              readOnly={true}
            />

            <div className="d-flex justify-content-end">
              <p>{textMemo?.length}/500</p>
            </div>

            <div className="hstack gap-2 mt-3 justify-content-end">
              <button
                className="btn btn-soft-secondary fs-14"
                type="button"
                color="light"
                onClick={() => {
                  setOpen(0)
                }}
              >
                {t("media.Cancel")}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <MediaModal
        media={media}
        onClose={() => setMedia(false)}
        onRefresh={() => paging()}
      />
    </React.Fragment>
  );
};

export default MediaDeleteMember;
