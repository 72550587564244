import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  NavLink,
  ModalBody,
  Modal,
  ModalHeader,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
//
import articleManagementApi from "api/articleManagementApi";
import { IresMediaCmsHub } from "api/types/_cmsHub";
import { IPagination } from "api/types/general";
import DatePickerCustom from "components/Common/DatePickerCustom";
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync, formatNumber } from "helpers/general";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../components/Common/TableContainer";
import BreadCrumb from "components/Common/BreadCrumb";
import DropdownOption from "components/Common/DropdownOption";
import { TYPE_TRANSMISSION_FORMAT_ARTICLE_STATISTICS_OPTIONS } from "helpers/constans";
import classnames from "classnames";
import D_disabled from "../../assets/images/articles/D_disabled.png";
import N_disabled from "../../assets/images/articles/N_disabled.png";
import Z_disabled from "../../assets/images/articles/Z_disabled.png";
import N2_disabled from "../../assets/images/articles/N2_disabled.png";
import D from "../../assets/images/articles/D.png";
import N from "../../assets/images/articles/N.png";
import Z from "../../assets/images/articles/Z.png";
import N2 from "../../assets/images/articles/N2.png";
import { useModal } from "context/confirm.context";

const renderPortalTransmission = (handleEvent: any) => {
  const isDisabled = true;
  return (
    <div className="d-flex gap-2 justify-content-center">
      <img
        onClick={handleEvent}
        className="cursor-pointer"
        src={isDisabled ? N_disabled : N}
        alt=""
        height="20"
      />
      <img
        onClick={handleEvent}
        className="cursor-pointer"
        src={isDisabled ? D_disabled : D}
        alt=""
        height="20"
      />
      <img
        onClick={handleEvent}
        className="cursor-pointer"
        src={isDisabled ? N2_disabled : N2}
        alt=""
        height="20"
      />
      <img
        onClick={handleEvent}
        className="cursor-pointer"
        src={isDisabled ? Z_disabled : Z}
        alt=""
        height="20"
      />
    </div>
  );
};

export default function ArticleStatistics() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [tabModal, setTabModal] = useState("1");
  const [detail, setDetail] = useState<any>();
  const { showModal } = useModal();
  const defaultParams = {
    page: 1,
    page_size: 30,
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    service_type: "",
  };
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const defaultRangeDate = [
    queryParams?.start_date ? moment(queryParams?.start_date || "", "Y-MM-DD").toDate() : null,
    queryParams?.end_date ? moment(queryParams?.end_date || "", "Y-MM-DD").toDate(): null,
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;

  const [rows, setRows] = useState<Array<IresMediaCmsHub>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const companies = useSelector((state: any) => state?.Company?.companies);
  const [category, setCategory] = useState<any>();
  const TYPE_TRANSMISSION_FORMAT_ARTICLE_STATISTICS_OPTIONS_LANG =
    TYPE_TRANSMISSION_FORMAT_ARTICLE_STATISTICS_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    }));

  const [sort, setSort] = useState<any>();

  const companyOptions = useMemo(() => {
    return (
      companies?.map((item: any) => ({
        value: item?.id,
        label: item?.name,
      })) || []
    );
  }, [companies]);

  const [company_id, setCompanyId] = useState<any>();
  const [category_id, setCategoryId] = useState<any>();
  const [grade, setGrade] = useState<any>();

  const OPTIONS = [
    {
      value: "1",
      label: t("articles_management.Highest"),
    },
    {
      value: "2",
      label: t("articles_management.Lowest"),
    },
    {
      value: "3",
      label: t("articles_management.Latest"),
    },
  ];

  const GRADE = {
    ALL: 0,
    HEADLINE: 3,
    IMPORTANT: 2,
    NORMAL: 1,
  };

  const GRADE_LABEL = {
    [GRADE.ALL]: t("cms_media.all"),
    [GRADE.HEADLINE]: t("cms_media.headline"),
    [GRADE.NORMAL]: t("cms_media.nomal"),
    [GRADE.IMPORTANT]: t("cms_media.important_article"),
  };

  const gradeOptions = [
    {
      value: GRADE.ALL.toString(),
      label: t("cms_media.all"),
    },
    {
      value: GRADE.HEADLINE.toString(),
      label: t("cms_media.headline"),
    },
    {
      value: GRADE.IMPORTANT.toString(),
      label: t("cms_media.important_article"),
    },
    {
      value: GRADE.NORMAL.toString(),
      label: t("cms_media.nomal"),
    },
  ];

  const handleEvent = () => {
    showModal(
      {
        message: <div>{t("articles_management.CommingSoon")}</div>,
        submitButtonText: "확인",
        isHiddenClose: true,
      },
      async () => {}
    );
  };
  // Column
  const columns = useMemo(() => {
    const renderPV = (original: IresMediaCmsHub) => (
      <span>{formatNumber(original?.pv)}</span>
    );
    const renderUV = (original: IresMediaCmsHub) => (
      <span>{formatNumber(original?.uv)}</span>
    );

    return [
      {
        Header: t("cms_media.number"),
        accessor: "id",
        sortable: false,
        Cell: ({ row }: any) => (
          <div>
            {pagination.total -
              pagination.page_size * (pagination.page - 1) -
              (row?.index || 0)}
          </div>
        ),
      },
      {
        Header: t("articles_management.rating"),
        accessor: "grade",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">
            {GRADE_LABEL[row?.original?.grade || GRADE.NORMAL]}
          </div>
        ),
      },
      {
        Header: t("articles_management.section"),
        accessor: "category_name",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">{row.original?.category_name}</div>
        ),
      },
      {
        Header: t("articles_management.title"),
        accessor: "title",
        filterable: false,
        sortable: false,
        thWidth: "120px",
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div
            className="ellipsis-1 cursor-pointer"
            style={{
              maxWidth: "120px",
              textDecoration: "underline",
              textUnderlineOffset: "3px",
              display: "block",
            }}
            onClick={() =>
              window.open(
                `${row?.original?.domain}/post/${row?.original?.slug}`
              )
            }
          >
            {row.original?.title}
          </div>
        ),
      },
      {
        Header: t("articles_management.reporter_name"),
        accessor: "reporter_name",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">{row.original?.reporter_name}</div>
        ),
      },
      {
        Header: t("articles_management.publication_date"),
        accessor: "published_at",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">
            {moment(row?.original?.published_at).format("YYYY.MM.DD")}
          </div>
        ),
      },
      {
        Header: t("cms_media.PV"),
        accessor: "pv",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">{renderPV(row.original)}</div>
        ),
      },
      {
        Header: t("cms_media.UV"),
        accessor: "uv",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">{renderUV(row.original)}</div>
        ),
      },
      {
        Header: t("articles_management.incoming_users"),
        accessor: "inflow_user",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">
            {formatNumber(row?.original?.inflow_user)}
          </div>
        ),
      },
      {
        Header: t("articles_management.inflow_keywords"),
        accessor: "inflow_keywords",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => {
          if (!row.original?.inflow_keywords?.length) {
            return <div className="text-center">-</div>;
          }
          return (
            <div
              className="text-center cursor-pointer"
              onClick={() => {
                setDetail(row?.original);
                setTabModal("2");
              }}
            >
              {row.original?.inflow_keywords?.[0]?.key} +{" "}
              {row.original?.inflow_keywords?.[0]?.quantity}
            </div>
          );
        },
      },
      {
        Header: t("articles_management.funnel"),
        accessor: "",
        thClass: "text-center",
        Cell: ({ row }: any) => {
          return (
            <div
              className="text-center"
              style={{
                textDecoration: "underline",
                textUnderlineOffset: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                setDetail(row?.original);
                setTabModal("1");
              }}
            >
              {t("articles_management.viewDetail")}
            </div>
          );
        },
      },
      {
        Header: t("articles_management.portal_transmission"),
        accessor: "portal_transmission",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: () => {
          return (
            <div className="text-center">
              {renderPortalTransmission(handleEvent)}
            </div>
          );
        },
      },
    ];
  }, [t, i18n?.language, pagination]);

  const paging = async () => {
    const [err, result] = await catchAsync(
      articleManagementApi.articles(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  const getCategory = async () => {
    const [err, result] = await catchAsync(
      articleManagementApi.articlesCategory({ company_id })
    );
    if (err) {
      console.log(err);
      return;
    }
    if (!result?.data?.length) {
      setCategory([]);
      return;
    }
    setCategory(
      result?.data.map((item: any) => ({ label: item.name, value: item.id }))
    );
  };

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      page: page + 1,
    });
  };

  const handleSearchCategory = () => {
    setQueryParams({
      ...queryParams,
      category_id,
      company_id,
      grade,
      page: 1,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
    });
  };

  const resetData = () => {
    clearQueryParams();
    setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
    setCompanyId(null);
    setCategoryId(null);
    setGrade(null);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  useEffect(() => {
    if (company_id) {
      getCategory();
    }
  }, [company_id]);

  useEffect(() => {
    paging();
  }, [location.search]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("articles_management.articles_list")}
            pageTitle={t("articles_management.articles_management")}
          />
          <Card id="leadsList">
            <CardHeader className="border-0">
              <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                <div className="w-100">
                  <Row>
                    <Col
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="mt-2 mt-md-0 mb-2 mb-md-0"
                    >
                      <div
                        className="d-flex align-items-center date-picker-wrapper-custom"
                        style={{ gap: "40px" }}
                      >
                        <div className="fw-bold text-nowrap">
                          {t("cms_media.period")}
                        </div>
                        <div style={{ width: "300px" }}>
                          <DatePickerCustom
                            placeholder={`${t("A From Range")} - ${t(
                              "A To Range"
                            )}`}
                            startDate={startDate || null}
                            endDate={endDate || null}
                            disabled={false}
                            isClearable={true}
                            onChangePicker={handleChangePicker}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-4 align-items-center mb-2 mt-2">
                    <Col sm={12} lg={6} className="mt-2">
                      <div
                        className="d-flex align-items-center date-picker-wrapper-custom"
                        style={{ gap: "40px" }}
                      >
                        <div className="fw-bold text-nowrap">
                          {t("articles_management.media")}
                        </div>
                        <div style={{ width: "300px" }}>
                          <DropdownOption
                            name="type"
                            dataList={companyOptions || []}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={
                              companyOptions?.find(
                                (item: any) => item.value == company_id
                              ) || {
                                label: t("articles_management.All"),
                                value: "0",
                              }
                            }
                            onChangeSelect={(event: any) => {
                              setCompanyId(event?.value);
                            }}
                            isHasOptionAll={true}
                            optionAll={{
                              label: t("articles_management.All"),
                              value: "0",
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-4 align-items-center mb-2 mt-2">
                    <Col sm={12} lg={6} className="mt-2">
                      <div
                        className="d-flex align-items-center date-picker-wrapper-custom"
                        style={{ gap: "40px" }}
                      >
                        <div className="fw-bold text-nowrap">
                          {t("articles_management.rating")}
                        </div>
                        <div style={{ width: "300px" }}>
                          <DropdownOption
                            name="type"
                            dataList={gradeOptions || []}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={
                              gradeOptions?.find(
                                (item: any) => item.value == grade
                              ) || {
                                label: t("articles_management.All"),
                                value: "0",
                              }
                            }
                            onChangeSelect={(event: any) => {
                              setGrade(event?.value);
                            }}
                            isHasOptionAll={false}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-4 align-items-center mb-2 mt-2">
                    <Col sm={12} lg={12} className="mt-2">
                      <div className="d-flex align-items-center gap-3">
                        <div
                          className="d-flex align-items-center date-picker-wrapper-custom"
                          style={{ gap: "40px" }}
                        >
                          <div className="fw-bold text-nowrap">
                            {t("articles_management.section")}
                          </div>
                          <div style={{ width: "300px" }}>
                            <DropdownOption
                              name="type"
                              dataList={category || []}
                              className="search-filter-category-type"
                              classNamePrefix="name-prefix"
                              initialValue={
                                category?.find(
                                  (item: any) => item.value == category_id
                                ) || {
                                  label: t("articles_management.All"),
                                  value: "0",
                                }
                              }
                              onChangeSelect={(event: any) => {
                                setCategoryId(event?.value);
                              }}
                              isHasOptionAll={true}
                              optionAll={{
                                label: t("articles_management.All"),
                                value: "0",
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-flex gap-2">
                          <button
                            type="button"
                            className="btn btn-primary"
                            disabled={loading}
                            onClick={handleSearchCategory}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t("Button Search")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t("Button Reset")}
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Nav
                className="nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0"
                role="tablist"
              >
                {TYPE_TRANSMISSION_FORMAT_ARTICLE_STATISTICS_OPTIONS_LANG.map(
                  (item, index) => (
                    <NavItem key={index}>
                      <NavLink
                        className={`cursor-pointer ${classnames({
                          active: queryParams?.service_type == item?.value,
                        })}`}
                        onClick={() => {
                          setQueryParams({
                            ...queryParams,
                            service_type: item?.value,
                          });
                        }}
                      >
                        {t(item?.label)}
                      </NavLink>
                    </NavItem>
                  )
                )}
              </Nav>
            </CardHeader>
            <CardBody className="pt-3">
              <div>
                <TableContainer
                  columns={columns}
                  data={rows || []}
                  customPageSize={pagination?.page_size}
                  customPageIndex={pagination?.page - 1}
                  totalRecords={pagination?.total}
                  customPageCount={Math.ceil(
                    pagination?.total / pagination?.page_size
                  )}
                  className="custom-header-css"
                  divClass="table-responsive table-card"
                  tableClass="align-middle"
                  theadClass="table-light"
                  handleChangePage={handleChangePage}
                  isLoading={loading}
                />
              </div>
              <ToastContainer closeButton={false} limit={1} />
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal
        isOpen={!!detail}
        centered={true}
        scrollable={false}
        style={{ minWidth: 800 }}
      >
        <ModalHeader>
          <Nav
            className="nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0 gap-3"
            role="tablist"
          >
            <NavItem>
              <NavLink
                className={`cursor-pointer ${classnames({
                  active: tabModal === "1",
                })}`}
                onClick={() => {
                  setTabModal("1");
                }}
              >
                {t("articles_management.funnel")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`cursor-pointer ${classnames({
                  active: tabModal === "2",
                })}`}
                onClick={() => {
                  setTabModal("2");
                }}
              >
                {t("articles_management.inflow_keywords")}
              </NavLink>
            </NavItem>
          </Nav>
        </ModalHeader>
        <ModalBody className="">
          <div>
            <div className="d-flex justify-content-end">
              <DropdownOption
                name="type"
                dataList={OPTIONS}
                className="search-filter-category-type mb-2"
                classNamePrefix="name-prefix"
                initialValue={sort || OPTIONS?.[0]}
                onChangeSelect={(event: any) => {
                  setSort(event);
                }}
              />
            </div>
            <div style={{ height: 400 }} className="overflow-y-auto">
              <Table className="table table-hover text-center position-relative">
                <thead className="table-light sticky-top z-0">
                  <tr role="row">
                    <th>{t("cms_media.number")}</th>
                    <th>
                      {tabModal === "1"
                        ? t("articles_management.URLAddress")
                        : t("articles_management.Keyword")}
                    </th>
                    <th>{t("articles_management.NumberOfSearches")}</th>
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  {(tabModal === "1"
                    ? detail?.inflow_websites
                    : detail?.inflow_keywords
                  )
                    ?.sort((a: any, b: any) =>
                      sort?.value === "3"
                        ? a?.latest - b?.latest
                        : sort?.value === "1"
                        ? b?.quantity - a?.quantity
                        : a?.quantity - b?.quantity
                    )
                    ?.map((item: any, index: number) => (
                      <tr>
                        <td role="cell">
                          {(tabModal === "1"
                            ? detail?.inflow_websites
                            : detail?.inflow_keywords
                          )?.length - index}
                        </td>
                        <td role="cell">{item?.key}</td>
                        <td role="cell">{item?.quantity}</td>
                      </tr>
                    ))}
                  {!(
                    tabModal === "1"
                      ? detail?.inflow_websites
                      : detail?.inflow_keywords
                  )?.length && (
                    <tr>
                      <td colSpan={3}>No Data</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            <div className="text-center">
              <button
                className="btn btn-primary fs-14"
                type="button"
                color="light"
                onClick={() => setDetail(null)}
              >
                {t("cms_media.check")}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
