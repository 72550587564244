import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Container, Spinner } from "reactstrap";
import { catchAsync } from "helpers/general";
import authApi from "api/authApi";
import { toast, ToastContainer } from "react-toastify";
import { removeEmptyFields } from "../../../utils/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loginSuccess } from "store/auth/login/reducer";

const ProfileInformation = () => {
  const { t } = useTranslation();
  const user: any = useSelector((state: any) => state?.Login);
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPasswordShow, setCurrentPasswordShow] = useState<boolean>(false);
  const [newPasswordShow, setNewPasswordShow] = useState<boolean>(false);

  const validationSchema = Yup.object({
    isUpdatePassword: Yup.boolean(),
    new_password: Yup.string().when("isUpdatePassword", {
      is: true,
      then: (schema) => schema.required("").min(1, "").max(50),
      otherwise: (schema) => schema,
    }),
    current_password: Yup.string().when("isUpdatePassword", {
      is: true,
      then: (schema) => schema.required(""),
      otherwise: (schema) => schema,
    }),
    name: Yup.string().max(50).required(""),
  });
  const formik = useFormik<{
    email: string;
    current_password: string;
    new_password: string;
    name: string;
    note: string;
    isUpdatePassword: boolean;
  }>({
    initialValues: {
      email: "",
      current_password: "",
      new_password: "",
      name: "",
      note: "",
      isUpdatePassword: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values: {
    email: string;
    current_password: string;
    new_password: string;
    name: string;
    note: string;
    isUpdatePassword?: boolean;
  }) => {
    const request = {...values}
    delete request.isUpdatePassword;
    const [error, result] = await catchAsync(
      authApi.updateProfile(request),
      setLoading
    );
    if (error) {
      toast.error(error?.errors?.current_password || error?.message);
      if (error?.errors) formik.setErrors(error?.errors);
      return;
    }
    const userUpdated = {
      ...user,
      ...user?.admin,
      admin: result?.data,
    };
    localStorage.setItem("authUser", JSON.stringify(userUpdated));
    dispatch(loginSuccess(userUpdated));
    toast.success(t("account.The_information_update_successfully"));
  };

  const handleGetProfile = async () => {
    const [error, result] = await catchAsync(authApi.profile());
    if (error) {
      toast(error?.errors?.email || error?.message);
      if (error?.errors) formik.setErrors(error?.errors);
      return;
    }
    const { data } = result;
    formik.setFieldValue("email", data?.email);
    formik.setFieldValue("name", data?.name);
    formik.setFieldValue("note", data?.note);
  };

  useEffect(() => {
    handleGetProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // document.title = `Super Admin`;

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <div className='page-content'>
        <Container fluid>
          <div className='d-flex align-items-center gap-2 mb-3'>
            <h4 className='mb-sm-0'>{t("account.Profile_information")}</h4>
          </div>
          <form onSubmit={formik.handleSubmit} style={{ maxWidth: "500px" }} className='mx-auto'>
            <div className='d-flex flex-column gap-4'>
              <div>
                <label className='form-label'>{t("account.email")}</label>
                <input
                  className='form-control'
                  id='email'
                  name='email'
                  value={formik?.values?.email}
                  placeholder={t("account.email_placeholder")}
                  onChange={(event: any) =>
                    formik.setFieldValue("email", event?.target?.value || "")
                  }
                  maxLength={50}
                  disabled={true}
                />
                {formik.errors.email && (
                  <div className='text-danger mt-2'>{formik.errors.email}</div>
                )}
              </div>
              <div className='d-flex flex-column'>
                {!formik.values.isUpdatePassword && (
                  <label className='form-label'>{t("account.password")}</label>
                )}
                {formik.values.isUpdatePassword && (
                  <div className='d-flex flex-column gap-4' style={{ marginBottom: "1.5rem" }}>
                    <div>
                      <label className='form-label'>
                        {t("account.current_password.label")}
                        <span className='text-danger'> *</span>
                      </label>
                      <div className='position-relative'>
                        <input
                          className='form-control'
                          type={currentPasswordShow ? "text" : "password"}
                          id='current_password'
                          name='current_password'
                          value={formik?.values?.current_password}
                          placeholder={t("account.current_password.placeholder")}
                          onChange={(event: any) =>
                            formik.setFieldValue("current_password", event?.target?.value || "")
                          }
                          maxLength={50}
                        />
                        <button
                          className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                          onClick={() => setCurrentPasswordShow(!currentPasswordShow)}
                          type='button'
                          id='password-addon'
                        >
                          <i
                            className={`${
                              currentPasswordShow ? "ri-eye-fill" : "ri-eye-off-fill"
                            } align-middle`}
                          ></i>
                        </button>
                      </div>
                      {formik.errors.current_password && (
                        <div className='text-danger mt-2'>{formik.errors.current_password}</div>
                      )}
                    </div>
                    <div>
                      <label className='form-label'>
                        {t("account.new_password.label")}
                        <span className='text-danger'> *</span>
                      </label>
                      <div className='position-relative'>
                        <input
                          className='form-control'
                          type={newPasswordShow ? "text" : "password"}
                          id='new_password'
                          name='new_password'
                          value={formik?.values?.new_password}
                          placeholder={t("account.new_password.placeholder")}
                          onChange={(event: any) =>
                            formik.setFieldValue("new_password", event?.target?.value || "")
                          }
                          maxLength={50}
                        />
                        <button
                          className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                          onClick={() => setNewPasswordShow(!newPasswordShow)}
                          type='button'
                          id='password-addon'
                        >
                          <i
                            className={`${
                              newPasswordShow ? "ri-eye-fill" : "ri-eye-off-fill"
                            } align-middle`}
                          ></i>
                        </button>
                      </div>
                      {formik.errors.new_password && (
                        <div className='text-danger mt-2'>{formik.errors.new_password}</div>
                      )}
                    </div>
                  </div>
                )}
                <div>
                  <input
                    type='checkbox'
                    className='btn-check'
                    // checked
                    autoComplete='off'
                    id='isUpdatePassword'
                    name='isUpdatePassword'
                    onChange={(event: any) => {
                      if (!event?.target?.checked) {
                        formik.setFieldValue("current_password", "");
                        formik.setFieldValue("new_password", "");
                      }
                      formik.setFieldValue("isUpdatePassword", event?.target?.checked || "");
                    }}
                  />
                  <label
                    className={`btn fs-14 ${
                      formik.values.isUpdatePassword ? "btn-light text-dark" : "btn-primary"
                    }`}
                    htmlFor='isUpdatePassword'
                    style={{
                      width: "150px",
                    }}
                  >
                    {formik.values.isUpdatePassword
                      ? t("account.btn.cancel_changes")
                      : t("account.btn.change_password")}
                  </label>
                </div>
              </div>
              <div>
                <label className='form-label'>
                  {t("account.name")}
                  <span className='text-danger'> *</span>
                </label>
                <input
                  className='form-control'
                  id='name'
                  name='name'
                  value={formik?.values?.name}
                  placeholder={t("account.name_placeholder")}
                  onChange={(event: any) =>
                    formik.setFieldValue("name", event?.target?.value || "")
                  }
                  maxLength={10}
                />
                {formik.errors.name && <div className='text-danger mt-2'>{formik.errors.name}</div>}
              </div>
              <div>
                <label className='form-label'>{t("account.note")}</label>
                <textarea
                  className='form-control'
                  id='note'
                  name='note'
                  value={formik?.values?.note || ""}
                  rows={6}
                  placeholder={t("account.note_placeholder")}
                  maxLength={500}
                  onChange={(event: any) =>
                    formik.setFieldValue("note", event?.target?.value || "")
                  }
                />
                {formik.errors.note && <div className='text-danger mt-2'>{formik.errors.note}</div>}
              </div>
            </div>
            <div className='d-flex justify-content-center mt-5 gap-3'>
              <button
                type='submit'
                className='btn btn-primary fs-14'
                style={{
                  width: "200px",
                }}
                disabled={loading || !formik.dirty || !formik.isValid}
              >
                {loading && (
                  <Spinner size='sm' className='me-2'>
                    {t("Loading")}
                  </Spinner>
                )}
                {t("account.btn.save_change")}
              </button>
            </div>
          </form>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default ProfileInformation;
