import { APIClient } from "./axiosClient"
const api = new APIClient();

const importApi = {
  dumpSqlTempDatabases() {
    return api.get(`imports/dump-sql/temporary-databases`)
  },
  dumpSqlSteps() {
    return api.get(`imports/dump-sql/steps`)
  },
  dumpSqlRequest(partner_name: string) {
    return api.post(`imports/dump-sql/request`,{partner_name})
  },
  dumpSqlPrepare(params: any) {
    return api.post(`imports/dump-sql/prepare`,params)
  },
  dumpSqlPrepareStatus(partner_name: string,job_id:string) {
    return api.get(`imports/dump-sql/prepare-status`,{partner_name,job_id})
  },
  dumpSqlImportProcess(partner_name: string,company_id:number) {
    return api.post(`imports/dump-sql/process`,{partner_name,company_id})
  },
  dumpSqlImportProcessStatus(partner_name: string,job_id:string) {
    return api.get(`imports/dump-sql/process-status`,{partner_name,job_id})
  },
  dumpSqlImportTemplates() {
    return api.get(`imports/dump-sql/templates`)
  },
}
export default importApi
