import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DatePicker from "react-datepicker";
//
import "react-datepicker/dist/react-datepicker.css";

interface Props {
  date?: string | null;
  minDate?: string;
  isNotFilterTime?: boolean;
  isNotshowTimeSelect?: boolean;
  className?: string;
  wrapperClassName?: string;
  dateFormat?: string;
  placeholder?: string;
  readOnly?: boolean;
  onChange?: (value: any) => void;
}
const DateTimePickerCustom = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [minDate,setMinDate] = useState<Date | null>(new Date());
  const [date, setDate] = useState<Date | null | any>(new Date());

  const handleChangeDate = (date: Date | null) => {
    setDate(date);
    if (props.onChange) {
      props.onChange(moment(date).toISOString());
    }
  };

  const filterPassedTime = (time: any) => {
    const selectedDate = new Date(time);
    if (props.minDate) {
      const minDate = new Date(props.minDate);
      return selectedDate.getTime() >= minDate.getTime();
    }
    return true;
  };

  useEffect(() => {
    if (props.date) {
      const date = new Date(props.date);
      setDate(date);
    } else {
      setDate(null);
    }
  }, [props.date]);

  useEffect(() => {
    setMinDate(props.minDate ? new Date(props.minDate) : new Date());
  }, [props.minDate]);

  return (
    <div className="flex items-center gap-[8px] data-time-picker">
       <DatePicker
        wrapperClassName={props.wrapperClassName}
        className={`form-control ${props.className ? props.className : 'search'}`}
        placeholderText={props.placeholder || `${t('version_date')}...`}
        value={date || undefined}
        selected={date || undefined}
        dateFormat={props.dateFormat || `yyyy-MM-dd h:mm a`}
        isClearable={false}
        minDate={minDate}
        locale={i18n?.language === 'ko' ? 'ko' : 'en'}
        onChange={(date) => handleChangeDate(date)}
        showTimeSelect={!props.isNotshowTimeSelect ? true : false}
        timeFormat="p"
        timeIntervals={1}
        filterTime={!props.isNotFilterTime ? filterPassedTime : undefined}
        readOnly={props?.readOnly}
        />
    </div>
  );
};

export default DateTimePickerCustom;
