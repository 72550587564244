import axios, { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";

import { QueryParams } from "components/Hooks/useQueryParams";
const api = new APIClient();

const path = "/transfer-applications";

const transferApi = {
  getSummaries(params: any): Promise<AxiosResponse<Array<any>, any>> {
    const url = `${path}/summary`;
    return api.get(url, params);
  },
  getList(dataForm: QueryParams): Promise<AxiosResponse<any, any>> {
    const url = `${path}`;
    return api.get(url, dataForm);
  },
  updateStatus({
    id,
    body,
  }: {
    id: number;
    body: { status: number };
  }): Promise<AxiosResponse<any, any>> {
    const url = `${path}/update-status/${id}`;
    return api.post(url, body);
  },
  addComment({
    id,
    body,
  }: {
    id: number;
    body: { content: string };
  }): Promise<AxiosResponse<any, any>> {
    const url = `${path}/comments/${id}`;
    return api.post(url, body);
  },

  downloadFile({ url, name }: any): Promise<AxiosResponse<any, any>> {
    // const url = `${path}/comments/${id}`;
    return axios.get(url);
  },
};
export default transferApi;
