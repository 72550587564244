import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync } from "helpers/general";
import { IPagination } from "api/types/general";
import TableContainer from "components/Common/TableContainer";
import accountApi from "api/accountApi";
import BreadCrumb from "components/Common/BreadCrumb";

const Account = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 30,
  };
  const { getQueryParams, setQueryParams } = useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const [keyword, setKeyword] = useState<string>("");
  const [rows, setRows] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({} as IPagination);

  const columns = useMemo(
    () => [
      {
        Header: t("account.number"),
        accessor: "number",
        sortable: false,
        Cell: (cell: any) => {
          const { row } = cell;
          return (
            <div style={{ minWidth: "60px" }}>
              {pagination.total - pagination.page_size * (pagination.page - 1) - row?.index}
            </div>
          );
        },
      },
      {
        Header: t("account.email"),
        accessor: "email",
        sortable: false,
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            {cell?.value ? (
              <p
                className="mb-0"
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "150px",
                }}
              >
                {cell?.value}
              </p>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        Header: t("account.name"),
        accessor: "name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            {cell?.value ? (
              <p
                className="mb-0"
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "150px",
                }}
              >
                {cell?.value}
              </p>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        Header: t("account.authority"),
        accessor: "permissions",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const { value } = cell;
          return (
            <div
              className="d-flex gap-1"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                minWidth: "150px",
              }}
            >
              {value?.length > 0 &&
                value.map((item: string, index: number) => (
                  <p key={index} className="mb-0">
                    {t(`account.permissions.${item}`)}
                    {index !== value?.length - 1 && ", "}
                  </p>
                ))}
            </div>
          );
        },
      },
      {
        Header: t("account.note"),
        accessor: "note",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            {cell?.value ? (
              <p
                className="mb-0"
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "150px",
                }}
              >
                {cell?.value}
              </p>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      // {
      //   Header: t("account.action"),
      //   accessor: "id",
      //   filterable: false,
      //   sortable: false,
      //   Cell: (cell: any) => {
      //     const { value } = cell;
      //     return (
      //       <div
      //         style={{
      //           width: "60px",
      //         }}
      //       >
      //         <i
      //           className={"bx bx-edit fs-22 cursor-pointer"}
      //           onClick={() => {
      //             navigate(`/account/update?userID=${value}`);
      //           }}
      //         ></i>
      //       </div>
      //     );
      //   },
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, pagination]
  );

  const paging = async () => {
    const [err, result] = await catchAsync(accountApi.accounts(queryParams), setLoading);
    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  const handleChangePage = useCallback((page: any) => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: page + 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    paging();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    // document.title = `${t("Account")} - ${t("Account")} | SuperAdmin`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("account.menu_title")} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center mt-0">
                    <Col
                      sm={12}
                      lg={12}
                      className="hstack gap-1 flex-column flex-sm-row justify-content-sm-center justify-content-md-between mt-2 mb-2 mb-sm-3"
                    >
                      <div className="w-100 w-sm-auto text-end">
                        <button
                          type="button"
                          className="btn btn-primary fs-14"
                          onClick={() => navigate("/account/create")}
                        >
                          <i className="ri-add-fill align-bottom me-1"></i>{" "}
                          {t("account.btn.add_account")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={rows || []}
                      customPageSize={pagination?.page_size}
                      customPageIndex={pagination?.page - 1}
                      totalRecords={pagination?.total}
                      customPageCount={Math.ceil(pagination?.total / pagination?.page_size)}
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle text-center"
                      theadClass="table-light text-center"
                      rowClass="cursor-pointer"
                      handleChangePage={handleChangePage}
                      isLoading={loading}
                      onClickRow={(_: any, row: any) =>
                        navigate(`/account/update?userID=${row?.original?.id}`)
                      }
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Account;
