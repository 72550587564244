import { Card, CardBody, CardTitle, Input, Spinner, Tooltip } from "reactstrap";
import Select from "react-select";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
//
import { catchAsync } from "helpers/general";
import importApi from "api/importApi";
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";

function TooltipItem(props: any) {
  const { item, id } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <div
        color="secondary"
        className="cursor-pointer mt-1"
        id={"Tooltip-" + id}
      >
        {item.text}
      </div>
      <Tooltip
        placement={item.placement}
        isOpen={tooltipOpen}
        target={"Tooltip-" + id}
        toggle={toggle}
      >
        {props.content}
      </Tooltip>
    </>
  );
}

interface Props {
  nextStep?: (value: any) => void;
  prevStep: () => void;
  partner: any;
  company: any;
  template: any;
  activeStep: number;
}

interface Steps {
  name: string;
  description: string;
  fields: Array<{
    name: string;
    description: string;
    required: boolean;
  }>;
}

interface Options {
  table_name: string;
  table_comment: string;
  columns: Array<{
    column_name: string;
    data_type: string;
    column_type: string;
    column_comment: string;
    column_data_sample: string;
  }>;
}

const Mapping = ({
  nextStep,
  prevStep,
  partner,
  company,
  template,
  activeStep,
}: Props) => {
  const { t } = useTranslation();
  const [stepResource, setStepResource] = useState(0);
  const [arrays, setArrays] = useState<Array<Steps>>([] as Array<Steps>);
  const [options, setOptions] = useState<Array<Options>>([] as Array<Options>);
  const [loading, setLoading] = useState(false);

  const typeOptions = ["single", "date_time", "unix_timestamp", "custom"].map(
    (type) => ({
      value: type,
      label: type,
    })
  );

  const dumpSqlSteps = async () => {
    const [err, result] = await catchAsync(importApi.dumpSqlSteps());
    if (err) {
      console.log(err);
      toast(err?.message || err, CONFIG_OPTION_TOAST_ERROR);
      return;
    }
    setArrays(result.data);
  };

  const dumpSqlRequest = async () => {
    if (!partner) return;
    const [err, result] = await catchAsync(
      importApi.dumpSqlRequest(partner.value)
    );
    if (err) {
      console.log(err);
      toast(err?.message || err, CONFIG_OPTION_TOAST_ERROR);
      return;
    }
    setOptions(result.data);
  };

  const handleDumpSqlPrepare = async ({ steps }: any) => {
    const params = {
      partner_name: partner.value,
      company_id: company.value,
      steps: formatColumnMappings(steps),
    };
    const [err, result] = await catchAsync(
      importApi.dumpSqlPrepare(params),
      setLoading
    );
    if (err) {
      console.log(err);
      toast(err?.message || err, CONFIG_OPTION_TOAST_ERROR);
      return;
    }
    nextStep &&
      nextStep({
        partner_name: partner?.value,
        job_id: result?.data?.id,
      });
  };

  const itemResource = useMemo(() => {
    return options[stepResource] ? options[stepResource] : ({} as any);
  }, [options, stepResource]);

  const itemColumnResource = useCallback(
    (src_table: string) => {
      const item = options.find((item) => item.table_name === src_table);
      if (!item) {
        return [];
      }
      return item.columns.map((column) => ({
        label: `${column?.column_name}`,
        value: column?.column_name,
      }));
    },
    [options]
  );

  const sourceColumnValue = (
    array: any,
    value: Array<string>,
    type: string
  ) => {
    if (type !== "date_time") {
      return array?.find((item: any) => value?.includes(item?.value));
    }
    return array?.filter((item: any) => value?.includes(item?.value));
  };

  const optionTableResource = useMemo(() => {
    return options.map((item) => ({
      label: item?.table_name,
      value: item?.table_name,
    }));
  }, [options]);

  const initialValues = useMemo(() => {
    return {
      steps: template?.steps
        ? template?.steps
        : arrays.map((item) => ({
            name: item.name,
            src_table: "",
            filter: "",
            column_mappings: {},
          })),
    };
  }, [arrays, template]);

  const formatColumnMappings = (steps: any) => {
    return steps.map((step: any) => ({
      name: step.name,
      src_table: step.src_table,
      filter: step.filter ? JSON.parse(step.filter) : {},
      column_mappings: Object.values(step.column_mappings).map(
        (mapping: any) => ({
          source_columns: mapping.source_columns,
          destination_column: mapping.destination_column,
          type: mapping?.type || "single",
          custom_options: mapping?.custom_options
            ? JSON.parse(mapping?.custom_options)
            : {},
        })
      ),
    }));
  };

  const renderSteps = (props: any) => {
    const { values, setFieldValue } = props;
    return arrays.map((item, index) => {
      return (
        <Card key={index}>
          <CardBody>
            <div className="text-center mb-3">
              <h3>{item.description}</h3>
            </div>
            <CardTitle>
              <div
                className="d-flex align-items-center"
                style={{ gap: "40px" }}
              >
                <div style={{ whiteSpace: "nowrap", minWidth: "150px",fontWeight:'600' }}>
                  {item?.name}
                </div>
                <div style={{ minWidth: "15px", height: "15px" }}></div>
                <div className="d-flex align-items-center gap-2 w-100">
                  <div style={{ minWidth: "140px", maxWidth: "140px" }}>
                    <Select
                      options={optionTableResource}
                      className="w-100"
                      placeholder={t("cms_media.select_table")}
                      name={`steps[${item.name}].src_table`}
                      value={optionTableResource.find(
                        (itemChild) =>
                          itemChild.value === values.steps[index]?.src_table
                      )}
                      onChange={(e: any) => {
                        setFieldValue(`steps[${index}].src_table`, e?.value);
                      }}
                    />
                  </div>
                  <Input
                    className="w-100"
                    name={`steps[${index}].filter`}
                    value={values.steps[index]?.filter}
                    placeholder={t("cms_media.filter")}
                    onChange={(e: any) =>
                      setFieldValue(`steps[${index}].filter`, e?.target?.value)
                    }
                  />
                </div>
              </div>
            </CardTitle>
            <div className="d-flex gap-2 flex-column">
              {item.fields?.map((itemChild, indexChild) => {
                return (
                  <div
                    className="d-flex align-items-center"
                    key={indexChild}
                    style={{ gap: "40px" }}
                  >
                    <div className="d-flex flex-column" style={{ minWidth: "150px" }}>
                      <div className="d-flex gap-1" style={{whiteSpace:'nowrap',fontWeight:'500'}}>
                        {itemChild?.name} {itemChild?.required && (
                          <div style={{ color: "red" }}>*</div>
                        )}
                      </div>
                      <div> 
                        ({itemChild?.description})
                      </div>
                    </div>
                    <div>{"<-"}</div>
                    <div className="w-100 d-flex gap-2">
                      <div style={{ minWidth: "140px", maxWidth: "140px" }}>
                        <Select
                          options={typeOptions}
                          className="w-100"
                          placeholder="Select type"
                          value={{
                            value:
                              values.steps[index]?.column_mappings?.[
                                itemChild.name
                              ]?.type || "single",
                            label:
                              values.steps[index]?.column_mappings?.[
                                itemChild.name
                              ]?.type || "single",
                          }}
                          name={`steps[${index}].column_mappings[${itemChild.name}].type`}
                          onChange={(e: any) => {
                            setFieldValue(
                              `steps[${index}].column_mappings[${itemChild.name}].type`,
                              e.value
                            );
                            setFieldValue(
                              `steps[${index}].column_mappings[${itemChild.name}].destination_column`,
                              itemChild?.name
                            );
                          }}
                        />
                      </div>
                      <div className="w-100 d-flex gap-2">
                        <div style={{ width: "30%", minWidth: "160px" }}>
                          <Select
                            options={itemColumnResource(
                              values.steps[index]?.src_table || ""
                            )}
                            className="w-100"
                            isMulti={["date_time"].includes(
                              values.steps[index]?.column_mappings?.[
                                itemChild.name
                              ]?.type
                            )}
                            value={sourceColumnValue(
                              itemColumnResource(
                                values.steps[index]?.src_table || ""
                              ),
                              values.steps[index]?.column_mappings?.[
                                itemChild.name
                              ]?.source_columns,
                              values.steps[index]?.column_mappings?.[
                                itemChild.name
                              ]?.type
                            )}
                            placeholder={t("cms_media.select_column")}
                            name={`steps[${index}].column_mappings[${itemChild.name}].source_columns`}
                            onChange={(e: any) => {
                              setFieldValue(
                                `steps[${index}].column_mappings[${itemChild.name}].source_columns`,
                                typeof e.value === "string"
                                  ? [e.value]
                                  : e.map((item: any) => item.value)
                              );
                              setFieldValue(
                                `steps[${index}].column_mappings[${itemChild.name}].destination_column`,
                                itemChild?.name
                              );
                            }}
                          />
                        </div>
                        <div style={{ width: "70%" }}>
                          {["custom"].includes(
                            values.steps[index]?.column_mappings?.[
                              itemChild.name
                            ]?.type
                          ) && (
                            <Input
                              className="w-100"
                              name={`steps[${index}].column_mappings[${itemChild.name}].custom_options`}
                              value={
                                values.steps[index]?.column_mappings?.[
                                  itemChild.name
                                ]?.custom_options
                              }
                              placeholder={t("cms_media.custom_options")}
                              onChange={(e: any) => {
                                setFieldValue(
                                  `steps[${index}].column_mappings[${itemChild.name}].custom_options`,
                                  e?.target?.value
                                );
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </CardBody>
        </Card>
      );
    });
  };

  const checkStepConditions = (steps: any, fieldRequirements: any): boolean => {
    return steps.every((step: any) => {
      const requirement = fieldRequirements.find(
        (req: any) => req.name === step.name
      );

      if (requirement) {
        return requirement.fields.every((reqField: any) => {
          const mapping = step.column_mappings[reqField.name];
          if (reqField.required) {
            return (
              mapping &&
              mapping.destination_column !== "" &&
              mapping.source_columns.length > 0
            );
          }
          return true;
        });
      }

      return true;
    });
  };

  useEffect(() => {
    dumpSqlSteps();
  }, []);

  useEffect(() => {
    dumpSqlRequest();
  }, [partner]);

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={schema}
      onSubmit={(values) => {
        handleDumpSqlPrepare(values);
      }}
      enableReinitialize
    >
      {(props) => (
        <Form>
          <div className="d-flex gap-4">
            <div className="w-100">
              <FieldArray
                name="steps"
                render={() => <Form>{renderSteps(props)}</Form>}
              ></FieldArray>
            </div>
            <div
              style={{
                width: "500px",
                maxWidth: "500px",
                minWidth: "500px",
                position: "sticky",
                top: 90,
                zIndex: 999,
                height: "100%",
              }}
            >
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between items-center">
                    <div className="d-flex align-items-center">
                      <CardTitle
                        className="d-flex align-items-center gap-1"
                        style={{ fontSize: "16px" }}
                      >
                        <i className="bx bx-table"></i>{" "}
                        {itemResource?.table_name}
                      </CardTitle>
                    </div>
                    <div className="d-flex gap-2 cursor-pointer align-items-center">
                      <div
                        className="d-flex align-items-center"
                        onClick={() =>
                          stepResource - 1 >= 0
                            ? setStepResource(stepResource - 1)
                            : null
                        }
                      >
                        <i
                          className="bx bxs-chevron-left"
                          style={{
                            fontSize: "20px",
                            color:
                              stepResource - 1 >= 0
                                ? "black"
                                : "rgb(224, 224, 224)",
                          }}
                        ></i>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        {stepResource} / {options.length}
                      </div>
                      <div
                        className="d-flex align-items-center"
                        onClick={() =>
                          stepResource + 1 < options.length
                            ? setStepResource(stepResource + 1)
                            : null
                        }
                      >
                        <i
                          className="bx bxs-chevron-right"
                          style={{
                            fontSize: "20px",
                            color:
                              stepResource + 1 < options.length
                                ? "black"
                                : "rgb(224, 224, 224)",
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <div
                      className="d-flex gap-1"
                      style={{ borderBottom: "1px solid #E2E5EB" }}
                    >
                      <div style={{ minWidth: "170px", fontWeight: "bold" }}>
                        {t("cms_media.column")}
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        {t("cms_media.data")}
                      </div>
                    </div>
                    {itemResource?.columns?.map(
                      (
                        item: {
                          column_name: string;
                          column_comment: string;
                          column_data_sample: string;
                        },
                        index: number
                      ) => {
                        return (
                          <div
                            className="d-flex gap-1"
                            key={index}
                            style={{ borderBottom: "1px solid #E2E5EB" }}
                          >
                            <div
                              className="d-flex flex-column"
                              style={{ minWidth: "170px" }}
                            >
                              <div style={{fontWeight:'500'}}>{item.column_name} </div>
                             {item.column_comment && <div>({item.column_comment})</div>}
                            </div>
                            <div
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.column_data_sample || "-"}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mb-5">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={prevStep}
            >
              {t("cms_media.prev")}
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                !checkStepConditions(props.values.steps, arrays) || loading
              }
            >
              {loading && <Spinner size="sm" className="me-2" />}
              {t("cms_media.next")}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Mapping;
