import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Modal, ModalBody, Spinner } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useModal } from "context/confirm.context";
import cmsHubMembershipApi from "api/membershipApi";
import { catchAsync } from "helpers/general";
import { toast } from "react-toastify";

interface Props {
  isOpen: boolean;
  data: any;
  onClose: () => void;
  refresh: () => void;
}
const ModalUpdateMember = (props: Props) => {
  const { t } = useTranslation();
  const { showModal } = useModal();
  const [isInput, setIsInput] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);
  const [isAutoRenewal, setIsAutoRenewal] = useState(false);

  const createPopupSchema = Yup.object().shape({
    limit_hdd: Yup.number().optional().default(0),
    limit_daily_transfer: Yup.number().optional().default(0),
    amount: Yup.number().optional().default(0),
    point: Yup.number().optional().default(0),
    note: Yup.string().optional(),
  });

  const initialValues = {
    limit_hdd: 0,
    limit_daily_transfer: 0,
    point: 0,
    amount: 0,
    note: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createPopupSchema,
    onSubmit: async (values) => {
      if (
        !values.limit_hdd ||
        !values.limit_daily_transfer ||
        !values.point ||
        !values.amount
        || values.limit_hdd == 0
        || values.limit_daily_transfer == 0 
        || values.point == 0
        || values.amount == 0
      ) {
        showModal(
          {
            title: t("membership.check"),
            message: t("membership.please_enter_a_numerical_value"),
            submitButtonText: t("membership.check"),
            isHiddenClose: true,
          },
          () => {}
        );
        return;
      }
      values.limit_hdd = typeof values.limit_hdd === "string" ? parseInt(values.limit_hdd) : values.limit_hdd;
      values.limit_daily_transfer = typeof values.limit_daily_transfer === "string" ? parseInt(values.limit_daily_transfer) : values.limit_daily_transfer;
      values.point = typeof values.point === "string" ? parseInt(values.point) : values.point;
      values.amount = typeof values.amount === "string" ? parseInt(values.amount) : values.amount;
      const [error, _] = await catchAsync(
        cmsHubMembershipApi.sendEnterPriseFee(props.data?.company_id, values),
        setLoading
      );
      if (error) {
        toast.error(error?.message || error);
        return;
      }
      showModal(
        {
          title: t("membership.check"),
          message: <div>
            {t("membership.your_membership_change_has_been_completed")} <br/>
            {t("membership.we_will_send_you_a_payment_email")}
          </div>,
          submitButtonText: t("membership.check"),
          isHiddenClose: true,
        },
        () => props.onClose()
      );
      props.refresh();
      return;
    },
  });

  const isCheckDiffValue = useMemo(()=>{
    return formik.values.limit_hdd != props.data?.limit_hdd || formik.values.limit_daily_transfer != props.data?.limit_daily_transfer
     ||  formik.values.note != props.data?.note || formik.values.point != props.data?.point || formik.values.amount != props.data?.amount;
  },[formik.values]);

  const defaultValue = () => {
    return {
      limit_hdd: props.data?.limit_hdd,
      limit_daily_transfer: props.data?.limit_daily_transfer,
      point: props.data?.point,
      amount: props.data?.amount,
      note: props.data?.note,
    }
  }

  const handleCancelMembership = async () => {
    const [error] = await catchAsync(
      cmsHubMembershipApi.deactivateMembership(props.data?.company_id),
      setLoadingSub
    );
    if (error) {
      console.log(error);
      toast.error(error?.message || error);
      return;
    }
    setIsAutoRenewal(false);
    props.refresh();
    showModal(
      {
        title: t("membership.check"),
        message: t("membership.your_membership_has_been_cancelled"),
        submitButtonText: t("membership.check"),
        isHiddenClose: true,
      },
      () => {}
    );
  };

  const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>,key: string) => {
    let value = e.target.value;
    if (value.match(/[^0-9]/)) {
      return;
    }
    value = value.replace(/^0+(?=[1-9])/, "");
    if (isNaN(parseInt(value)) || parseInt(value) < 0 || parseInt(value) == 0) {
      value = "0";
    }
    formik.setFieldValue(key, value);
  };

  useEffect(() => {
    if (props.data && props.isOpen) {
      formik.setValues(defaultValue());
      setIsAutoRenewal(props.data?.is_auto_renewal);
    } else {
      setIsInput(true);
      formik.resetForm();
    }
  }, [props.data, props.isOpen]);

  return (
    <Modal
      isOpen={props.isOpen}
      centered={true}
      scrollable={false}
      style={{ width: "520px",maxWidth:'520px' }}
    >
      <div className="d-flex justify-content-between pt-3 pb-2 px-3 items-center">
        <div></div>
        <div style={{ fontSize: "18px", fontWeight: "700" }}>
          {t("membership.change")}
        </div>
        <div onClick={() => props.onClose()}>
          <i
            className="ri-close-line"
            style={{ fontSize: "20px", cursor: "pointer" }}
          ></i>
        </div>
      </div>
      <ModalBody>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <div className="d-flex flex-column gap-3">
            <div className="d-flex gap-2 align-items-center">
              <button
                type="button"
                className="btn btn-danger"
                disabled={loadingSub || !isAutoRenewal}
                onClick={() => {
                  handleCancelMembership();
                }}
                style={{whiteSpace:'nowrap'}}
              >
                {t("membership.cancel_membership")}
              </button>
              <div style={{ fontWeight: "500", fontSize: "12px",fontFamily:'hkgrotesk' }}>
                {t("membership.if_cancel_membership")}
              </div>
            </div>
            <div className="d-flex gap-3 flex-wrap">
              <div className="d-flex gap-2">
                <div className="d-flex gap-2 align-items-center" >
                  <div style={{ minWidth: "100px" }}>HDD</div>
                  <div className={`wrapper ${isInput ? "disabled" : ""}`}>
                    <Input
                      type="text"
                      className="form-control"
                      {...formik.getFieldProps("limit_hdd")}
                      onChange={(e: any) => handleChangeNumber(e,"limit_hdd")}
                      style={{ textAlign: "right" }}
                      disabled={isInput}
                      maxLength={5}
                    />
                    <div className="prefix">GB</div>
                  </div>
                </div>
                <div className="d-flex gap-2 align-items-center" >
                  <div className="text-right" style={{ minWidth: "100px" }}>{t("membership.daily_transfer_volume")}</div>
                  <div className={`wrapper ${isInput ? "disabled" : ""}`}>
                    <Input
                      type="text"
                      className="form-control"
                      {...formik.getFieldProps("limit_daily_transfer")}
                      onChange={(e: any) => handleChangeNumber(e,"limit_daily_transfer")}
                      style={{ textAlign: "right" }}
                      disabled={isInput}
                      maxLength={5}
                    />
                    <div className="prefix">GB</div>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2">
                <div className="d-flex gap-2 align-items-center">
                  <div style={{ minWidth: "100px" }}>{t("membership.point")}</div>
                  <div className={`wrapper ${isInput ? "disabled" : ""}`}>
                    <Input
                      type="text"
                      className="form-control"
                      {...formik.getFieldProps("point")}
                      onChange={(e: any) => handleChangeNumber(e,"point")}
                      style={{ textAlign: "right" }}
                      disabled={isInput}
                      maxLength={9}
                    />
                    <div className="prefix">p</div>
                  </div>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <div className="text-right" style={{ minWidth: "100px" }}>{t("membership.payment_amount")}</div>
                  <div className={`wrapper ${isInput ? "disabled" : ""}`}>
                    <Input
                      type="text"
                      className="form-control"
                      {...formik.getFieldProps("amount")}
                      onChange={(e: any) => handleChangeNumber(e,"amount")}
                      style={{ textAlign: "right" }}
                      disabled={isInput}
                      maxLength={12}
                    />
                    <div className="prefix">원</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className={`btn ${!isInput ? "btn-primary" : "btn-secondary"}`}
                onClick={() => {
                  if (!isInput) {
                    formik.setValues(defaultValue());
                  }
                  setIsInput(!isInput);
                }}
                style={{ minWidth: "155px" }}
              >
                {isInput ? t("membership.change") : t("membership.cancel")}
              </button>
            </div>
            <div className="d-flex flex-column gap-1">
              <div>{t("membership.memo")}</div>
              <textarea
                className="form-control"
                id="jobDescription"
                rows={3}
                {...formik.getFieldProps("note")}
                maxLength={500}
              />
            </div>
            <div className="hstack gap-2 mt-3 justify-content-center px-5">
              <button
                className="btn btn-soft-secondary fs-14 w-100"
                type="button"
                color="light"
                onClick={() => props?.onClose()}
              >
                {t("cms_media.cancellation")}
              </button>
              <button type="submit" className="btn btn-primary fs-14 w-100" disabled={!isCheckDiffValue}>
                {loading ? <Spinner size="sm me-2"></Spinner> : null}
                {t("cms_media.save")}
              </button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default ModalUpdateMember;
