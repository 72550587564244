import { getAuth } from "helpers/cookie";

export const removeEmptyFields = (obj: any): any => {
  const result: any = {};

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        const nestedObject = removeEmptyFields(obj[key]);
        if (Object.keys(nestedObject).length > 0) {
          result[key] = nestedObject;
        }
      } else if (Array.isArray(obj[key])) {
        const filteredArray = obj[key]
          .map((item: any) => (typeof item === "object" ? removeEmptyFields(item) : item))
          .filter((item: any) => item !== null && item !== undefined);

        if (filteredArray.length > 0) {
          result[key] = filteredArray;
        }
      } else {
        result[key] = obj[key];
      }
    }
  }

  return result;
};

export const isSuperAdmin = () => {
  const auth = JSON.parse(getAuth() || "");
  return auth?.admin?.is_super_admin;
};

export function truncateFilename(filename: string, maxLength = 30) {
  const extension = filename.slice(filename.lastIndexOf("."));

  if (filename.length <= maxLength) {
    return filename;
  }

  const truncatedName =
    filename.slice(0, maxLength - extension.length + 4) + "... ";

  return truncatedName + extension;
}