import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import importApi from "api/importApi";
import { catchAsync } from "helpers/general";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";

const ChooseObject = ({
  activeStep,
  partner,
  company,
  setCompany,
  setPartner,
  template,
  setTemplate,
  nextStep,
  prevStep,
}: {
  activeStep: number;
  partner: any;
  company: any;
  template:any,
  nextStep: () => void;
  prevStep: () => void;
  setPartner: (value: any) => void;
  setTemplate:(value:any) => void,
  setCompany: (value: any) => void;
}) => {
  const {t  } = useTranslation();
  const [data, setData] = useState<any>([]);
  const [templates, setTemplates] = useState<any>([]);
  const companiesStore = useSelector((state: any) => state.Company.companies);
  const dumpSqlTempDatabases = async () => {
    const [err, result] = await catchAsync(importApi.dumpSqlTempDatabases());
    if (err) {
      console.log(err);
      toast(err?.message || err, CONFIG_OPTION_TOAST_ERROR);
      return;
    }
    setData(
      result?.data?.map((item: any) => ({
        label: item.db_name,
        value: item.partner_name,
      }))
    );
  };
  const dumpSqlImportTemplates = async () => {
    const [err, result] = await catchAsync(importApi.dumpSqlImportTemplates());
    if (err) {
      toast(err?.message || err, CONFIG_OPTION_TOAST_ERROR);
      return;
    };
    const formatData = result?.data?.map((item: any) => ({
      ...item,
      label: item.name,
      value: item.key,
      steps:item.steps.map((itemChild:any) => ({
        ...itemChild,
        filter:itemChild.filter && Object.keys(itemChild.filter).length > 0 ? JSON.stringify(itemChild.filter) : "",
        column_mappings: itemChild.column_mappings.reduce((acc:any, item:any) => {
          acc[item.destination_column] = {
            ...item,
            custom_options: item.custom_options && Object.keys(item.custom_options).length > 0 ? JSON.stringify(item.custom_options) : ""
          }
          return acc;
        }, {})
      })),
    }));
    setTemplates(formatData)
  };

  const companies = useMemo(() => {
    return companiesStore?.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));
  }, [companiesStore]);

  useEffect(() => {
    dumpSqlTempDatabases();
    dumpSqlImportTemplates();
  }, []);

  return (
    <>
      <div className="d-flex gap-2 justify-content-center">
        <Card
          style={{
            width: "80%",
          }}
        >
          <CardBody>
            <CardTitle tag="h5">{t("cms_media.load_mapping")}</CardTitle>
            <CardSubtitle className="mt-2 text-muted" tag="h6">
              <Form>
                <FormGroup>
                  <Label for="partner">{t("cms_media.resource")} <span style={{color: "red"}}>*</span></Label>
                  <Select options={data} value={partner} placeholder={t("cms_media.select_resource")} onChange={(e: any) => setPartner(e)}></Select>
                </FormGroup>
                <FormGroup>
                  <Label for="companies">{t("cms_media.company")} <span style={{color: "red"}}>*</span></Label>
                  <Select options={companies} value={company} placeholder={t("cms_media.select_company")} onChange={(e: any) => setCompany(e)}></Select>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleEmail">{t("cms_media.template")}</Label>
                  <Select options={templates} value={template} placeholder={t("cms_media.select_template")} onChange={(e: any) => setTemplate(e)}></Select>
                </FormGroup>
              </Form>
            </CardSubtitle>
          </CardBody>
        </Card>
      </div>
      <div className="d-flex gap-2 justify-content-center mb-5">
        <button type="button" className="btn btn-secondary" disabled={activeStep === 0} onClick={prevStep}>
          {t("cms_media.prev")}
        </button>
        <button type="button" className="btn btn-primary" disabled={!partner || !company} onClick={nextStep}>
        {t("cms_media.next")}
        </button>
      </div>
    </>
  );
};

export default ChooseObject;
