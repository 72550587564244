import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";
//
import "react-toastify/dist/ReactToastify.css";
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync, formatNumber } from "helpers/general";
import cmsHubMediaApi from "api/cmsHubMediaApi";
import { IPagination } from "api/types/general";
import DatePickerCustom from "components/Common/DatePickerCustom";
import { IresMediaCmsHub } from "api/types/_cmsHub";
import TableContainer from "components/Common/TableContainer";
import articleManagementApi from "api/articleManagementApi";
import BreadCrumb from "components/Common/BreadCrumb";
import { TYPE_TRANSMISSION_FORMAT_ARTICLE_STATISTICS_OPTIONS } from "helpers/constans";
import classnames from "classnames";

const ArticleList = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 30,
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    service_type: "",
  };
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const defaultRangeDate = [
    moment(queryParams?.start_date || "", "Y-MM-DD").toDate(),
    moment(queryParams?.end_date || "", "Y-MM-DD").toDate(),
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;

  const [keyword, setKeyword] = useState<string>("");
  const [rows, setRows] = useState<Array<IresMediaCmsHub>>([]);
  const [pagination, setPagination] = useState({} as IPagination);

  const TYPE_TRANSMISSION_FORMAT_ARTICLE_STATISTICS_OPTIONS_LANG =
    TYPE_TRANSMISSION_FORMAT_ARTICLE_STATISTICS_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    }));

  // Column
  const columns = useMemo(() => {
    return [
      {
        Header: t("cms_media.number"),
        accessor: "id",
        sortable: false,
        Cell: ({ row }: any) => (
          <div>
            {pagination.total -
              pagination.page_size * (pagination.page - 1) -
              (row?.index || 0)}
          </div>
        ),
      },
      {
        Header: t("cms_media.media_name"),
        accessor: "name",
      },
      {
        Header: (
          <div>
            <p className="m-0">
              {t("articles_management.newshub_total_published_articles")}
            </p>
            <div className="d-flex">
              <p className="m-0" style={{ width: "50%" }}>
                Presscon
              </p>
              {/* <p className="m-0" style={{ width: "33%" }}>
                News
              </p> */}
              <p className="m-0" style={{ width: "50%" }}>
                PR
              </p>
            </div>
          </div>
        ),
        accessor: "cmshub_total_published_articles",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="d-flex">
            <div style={{ width: "50%" }} className="text-center">
              <p className="m-0">
                {cell?.value ? formatNumber(cell?.value) : "-"}
              </p>
            </div>
            {/* <div style={{ width: "33%" }} className="text-center">
              <p className="m-0">
                {cell?.original?.newshub_total_published_articles
                  ? formatNumber(
                      cell?.original?.newshub_total_published_articles
                    )
                  : "-"}
              </p>
            </div> */}
            <div style={{ width: "50%" }} className="text-center">
              <p className="m-0">
                {cell?.original?.prhub_total_published_articles
                  ? formatNumber(cell?.original?.prhub_total_published_articles)
                  : "-"}
              </p>
            </div>
          </div>
        ),
      },
      {
        Header: t("articles_management.PVTotal"),
        accessor: "pv",
        thClass: "text-center",
        Cell: (cell: any) => <div className="text-center">{cell?.value}</div>,
      },
      {
        Header: t("articles_management.UVTotal"),
        accessor: "uv",
        thClass: "text-center",
        Cell: (cell: any) => <div className="text-center">{cell?.value}</div>,
      },
      {
        Header: t("articles_management.AveragePVForSetPeriod"),
        accessor: "avg_pv",
        thClass: "text-center",
        Cell: (cell: any) => <div className="text-center">{cell?.value}</div>,
      },
    ];
  }, [t, i18n?.language, pagination]);

  const paging = async () => {
    const [err, result] = await catchAsync(
      articleManagementApi.companies(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  // Search
  const handleSearchCategory = () => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: 1,
      start_date: startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : moment().subtract(1, "year").format("YYYY-MM-DD"),
      end_date: endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    });
  };

  const handleChangePage = useCallback((page: any) => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: page + 1,
    });
  }, []);

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  useEffect(() => {
    paging();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location.search]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("articles_management.articles_statistics")}
            pageTitle={t("articles_management.articles_management")}
          />
          <Card id="leadsList">
            <CardHeader className="border-0">
              <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                <div className="w-100">
                  <Row>
                    <Col
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="mt-2 mt-md-0 mb-2 mb-md-0"
                    >
                      <div
                        className="d-flex align-items-center date-picker-wrapper-custom"
                        style={{ gap: "40px" }}
                      >
                        <div className="fw-bold text-nowrap">
                          {t("cms_media.period")}
                        </div>
                        <div style={{ width: "300px" }}>
                          <DatePickerCustom
                            placeholder={`${t("A From Range")} - ${t(
                              "A To Range"
                            )}`}
                            startDate={startDate || null}
                            endDate={endDate || null}
                            disabled={false}
                            isClearable={true}
                            onChangePicker={handleChangePicker}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-4 align-items-center mt-0">
                    <Col sm={12} md={12} lg={6} xl={6} className="mt-2 mt-md-3">
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "40px" }}
                      >
                        <div className="fw-bold text-nowrap">
                          {t("cms_media.search")}
                        </div>
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("cms_media.input_search")}...`}
                          value={keyword}
                          onChange={(e) => setKeyword(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSearchCategory();
                            }
                          }}
                        />
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="mt-3 mt-md-3 hstack gap-1 justify-content-sm-center justify-content-md-between"
                    >
                      <div className="d-flex gap-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={loading}
                          onClick={handleSearchCategory}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t("Button Search")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t("Button Reset")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Nav
                className="nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0"
                role="tablist"
              >
                {TYPE_TRANSMISSION_FORMAT_ARTICLE_STATISTICS_OPTIONS_LANG.map(
                  (item, index) => (
                    <NavItem key={index}>
                      <NavLink
                        className={`cursor-pointer ${classnames({
                          active: queryParams?.service_type == item?.value,
                        })}`}
                        onClick={() => {
                          setQueryParams({
                            ...queryParams,
                            service_type: item?.value,
                          });
                        }}
                      >
                        {t(item?.label)}
                      </NavLink>
                    </NavItem>
                  )
                )}
              </Nav>
            </CardHeader>
            <CardBody className="pt-3">
              <div>
                <TableContainer
                  columns={columns}
                  data={rows || []}
                  customPageSize={pagination?.page_size}
                  customPageIndex={pagination?.page - 1}
                  totalRecords={pagination?.total}
                  customPageCount={Math.ceil(
                    pagination?.total / pagination?.page_size
                  )}
                  className="custom-header-css"
                  divClass="table-responsive table-card"
                  tableClass="align-middle"
                  theadClass="table-light"
                  handleChangePage={handleChangePage}
                  isLoading={loading}
                />
              </div>
              <ToastContainer closeButton={false} limit={1} />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ArticleList;
