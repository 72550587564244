import TableContainer from "components/Common/TableContainer";
import { catchAsync } from "helpers/general";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";
import DropdownOption from "components/Common/DropdownOption";
import { IPagination } from "api/types/general";
import pointApi from "api/pointApi";
import { IPoint } from "api/types/_point";
import GrantedDeductedPoint from "./GrantedDeductedPoint";
import ModalConfirm from "./ModalConfirm";

const serviceType = [
  {
    label: "Presscon",
    value: "1",
  },
  {
    label: "PR",
    value: "2",
  },
];

const Point = ({ id, refresh, onClose }: { id: number; refresh: any; onClose: any }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IPoint[]>([]);
  const [openConfirm, setOpenConfirm] = useState<any>(false);
  const [confirmData, setConfirmData] = useState<any>({});
  const [pagination, setPagination] = useState({
    page: 1,
    page_size: 10,
    total: 0,
  } as IPagination);
  const [deductPoints, setDeductPoints] = useState<string | number>("0");
  const [classification, setClassification] = useState<{ label: string; value: string }>(
    serviceType[0]
  );

  const [searchParams, setSearchParams] = useState({
    page: 1,
    page_size: 10,
    search: "",
    service_type: serviceType[0]?.value,
  });

  const columns = useMemo(
    () => [
      {
        Header: t("media.STT"),
        accessor: "number",
        thClass: "text-center",
        Cell: (cell: any) =>
          pagination?.total > 0 && (
            <div>
              {pagination.total -
                pagination.page_size * (pagination.page - 1) -
                (cell?.row?.index || 0)}
            </div>
          ),
      },

      {
        Header: t("media.Classification-point"),
        accessor: "service_type",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">
              {serviceType.find((item: any) => item.value == cell?.value)?.label}
            </p>
          </div>
        ),
      },
      {
        Header: t("media.MediaName"),
        accessor: "name",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">{cell?.value}</p>
          </div>
        ),
      },
      {
        Header: t("media.FreePoints"),
        accessor: "free_point",
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <div className="w-100 text-center">
              <p className="m-0">{cell?.value ? cell?.value : "-"}</p>
            </div>
          );
        },
      },
      {
        Header: t("media.PaidPoints"),
        accessor: "paid_point",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">{cell?.value ? cell?.value : "-"}</p>
          </div>
        ),
      },
      {
        Header: t("media.Point"),
        accessor: "id",
        thClass: "text-left",
        Cell: ({ row }: any) => (
          <GrantedDeductedPoint
            id={row?.original?.id}
            serviceType={row?.original?.service_type}
            refresh={() => getData(searchParams)}
          />
        ),
      },
    ],
    [i18n?.language, pagination]
  );

  const getData = async (searchParams: any) => {
    setLoading(true);
    try {
      const [err, result] = await catchAsync(pointApi.Companies(searchParams));

      if (result?.statusCode === 200) {
        setPagination(result?.data?.pagination);
        setData(result?.data?.items || []);
      }
      if (err) {
        console.log(err);
        return;
      }
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const updatePoint = async (type: string) => {
    setLoading(true);
    try {
      const [err, result] = await catchAsync(
        pointApi.updateAllPoint({
          type: type,
          data: {
            service_type: +searchParams?.service_type, // CMS_HUB: 1, PR_HUB: 2, NEWS_HUB: 3
            point: +deductPoints,
          },
        })
      );

      if (result?.statusCode === 200) {
        getData(searchParams);
      }
      if (err) {
        console.log(err);
        return;
      }
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setLoading(false);
      setOpenConfirm(false);
      setDeductPoints(0);
    }
  };

  const handleChangePage = (page: any) => {
    setSearchParams({
      ...searchParams,
      page: page + 1,
    });
    getData({
      ...searchParams,
      page: page + 1,
    });
  };

  useEffect(() => {
    getData(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-100 d-flex flex-column ">
      <p
        className="fw-bold text-black text-center mb-0"
        style={{ fontSize: "23px", lineHeight: "36px" }}
      >
        {t("media.PointsAward_Deduction")}
      </p>
      <div className="g-4 align-items-center mt-0 mt-md-2 mb-4 px-5 justify-content-center">
        <div className="d-flex gap-4 justify-content-center mt-0'">
          <div className="d-flex align-items-center gap-2">
            <p className="text-nowrap m-0">{t("media.Classification-point")}</p>
            <div style={{ width: "130px" }}>
              <DropdownOption
                name="type"
                dataList={serviceType}
                className="search-filter-category-type"
                classNamePrefix="name-prefix"
                initialValue={classification}
                onChangeSelect={(e: any) => {
                  if (e) {
                    setClassification(e);
                    setSearchParams({
                      ...searchParams,
                      service_type: e?.value,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <p className="text-nowrap m-0">{t("media.MediaName")}</p>
            <Input
              type="text"
              className="form-control search"
              style={{
                width: "229px",
              }}
              placeholder={`${t("media.PlaceholderSearch")}.`}
              value={searchParams?.search}
              onChange={(e) =>
                setSearchParams({
                  ...searchParams,
                  search: e?.target?.value,
                })
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  // getData(searchParams);
                  handleChangePage(0);
                }
              }}
            />
          </div>
          <div className="hstack gap-1 justify-content-sm-center justify-content-md-between">
            <div>
              <button
                type="button"
                className="btn btn-primary me-1"
                onClick={() => handleChangePage(0)}
                disabled={loading}
              >
                <i className="ri-search-line align-bottom me-1"></i> {t("Button Search")}
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-0">
          <div className="mt-3 mt-md-2 d-flex gap-2 align-items-center">
            <p className="text-nowrap m-0">{t("media.full_payment/deduction")}</p>
            <div className="position-relative w-100">
              <input
                autoFocus
                className="form-control"
                id="deduction_point"
                name="deduction_point"
                value={deductPoints}
                onChange={(event: any) => {
                  if (/^[0-9]*$/.test(event?.target?.value) || event?.target?.value === "") {
                    let value = event?.target?.value;
                    if (value.charAt(0) === "0") {
                      value = value.substring(1);
                    }
                    setDeductPoints(value);
                  }
                }}
                style={{
                  textAlign: "right",
                  paddingRight: "25px",
                  width: "171px",
                }}
                maxLength={9}
              />
              <p
                className="position-absolute"
                style={{
                  top: 8,
                  right: 10,
                }}
              >
                p
              </p>
            </div>
          </div>
          <div className="d-flex hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2">
            <div>
              <button
                type="button"
                className="btn btn-primary me-1"
                onClick={() => {
                  setConfirmData({
                    content: "Award_Points_All",
                    point: deductPoints,
                    type: "add",
                  });
                  setOpenConfirm(true);
                }}
                disabled={loading}
              >
                {t("media.Gift")}
              </button>
              <button
                type="button"
                className="btn btn-secondary fs-14"
                onClick={() => {
                  setConfirmData({
                    content: "Deduction_Points_All",
                    point: deductPoints,
                    type: "subtract",
                  });
                  setOpenConfirm(true);
                }}
              >
                {t("media.Deduct")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-fill">
        <TableContainer
          className="custom-header-css"
          divClass="table-responsive table-card m-0 h-100"
          tableClass="align-middle w-100"
          theadClass="table-light"
          columns={columns}
          data={data}
          isLoading={loading}
          isShowPagination={true}
          scrollbarOs={false}
          customPageSize={pagination?.page_size}
          customPageIndex={pagination?.page - 1}
          totalRecords={pagination?.total}
          customPageCount={Math.ceil(pagination?.total / pagination?.page_size)}
          handleChangePage={handleChangePage}
        />
      </div>
      <div className="text-center mt-2">
        <button
          type="button"
          onClick={() => {
            // setOpenConfirm(true);
            onClose();
          }}
          className="btn btn-primary mx-auto fs-14 w-25"
        >
          {t("media.Check")}
        </button>
      </div>

      <ModalConfirm
        header={""}
        isOpen={!!openConfirm}
        isShowIcon={false}
        isLoading={loading}
        title={t(`media.Check`)}
        content={t(`media.${confirmData?.content}`, { point: confirmData?.point })}
        textButtonConfirm={t("media.Check")}
        classButtonConfirm="btn-primary"
        classIconButtonConfirm=""
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => updatePoint(confirmData?.type)}
      />
    </div>
  );
};

export default Point;
