import React, { useEffect, useState } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
//
import useQueryParams from "components/Hooks/useQueryParams";
import Article from "./Article";
import { useTranslation } from "react-i18next";
import ArticleLog from "./ArticleLog";
import VisitorStatistic from "./VisitorStatistic";
import KeywordStatistic from "./KeywordStatistic";
import KeywordInflow from "./KeywordInflow";
import BreadCrumb from "components/Common/BreadCrumb";

const Statistics = () => {
  const { t } = useTranslation();
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const { clearQueryParams } = useQueryParams();
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  useEffect(() => {}, []);

  const tabs = [
    { id: "1", label: t("cms_statistics.ArticleStats"), content: <Article /> },
    { id: "2", label: t("cms_statistics.ArticleLog"), content: <ArticleLog /> },
    { id: "3", label: t("cms_statistics.VisitStats"), content: <VisitorStatistic /> },
    // { id: "4", label: t("cms_statistics.AdStats"), content: "tab4" },
    {
      id: "5",
      label: t("cms_statistics.KeywordInboundStats"),
      content: <KeywordInflow />,
    },
    { id: "6", label: t("cms_statistics.KeywordStats"), content: <KeywordStatistic/> },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("cms_statistics.statistics")} pageTitle={t("Presscon Ai")} />
          <Nav tabs className="nav nav-tabs nav-tabs-custom mb-3">
            {tabs.map((tab) => (
              <NavItem key={tab.id}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === tab.id,
                  })}
                  onClick={() => {
                    toggleCustom(tab.id);
                    clearQueryParams();
                  }}
                >
                  {tab.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <TabContent activeTab={customActiveTab} className="text-muted">
            {tabs.map((tab) => (
              <TabPane tabId={tab.id} key={tab.id} id={`tab-${tab.id}`}>
                {customActiveTab === tab.id && tab.content}
              </TabPane>
            ))}
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Statistics;
