import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
interface Option {
  label: string;
  value: string;
}
interface DropdownOptionProps {
  name?: string;
  initialValue?: Option | undefined | null;
  dataList?: Option[];
  onChangeSelect?: (params: Option) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  classNamePrefix?: string;
  isHasOptionAll?: boolean;
  optionAll?: undefined | Option;
  labelTop?: string;
  readOnly?: boolean;
}

const DropdownOption = ({
  name = "",
  initialValue,
  onChangeSelect,
  dataList = [],
  placeholder,
  disabled = false,
  className = "",
  classNamePrefix = "",
  isHasOptionAll = false,
  optionAll,
  labelTop = "",
  readOnly = false,
}: DropdownOptionProps) => {
  const { t } = useTranslation();

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  const options = useMemo(() => {
    const resultOptions =
      dataList?.map((item) => ({
        label: item?.label,
        value: String(item?.value || ""),
      })) || ([] as Option[]);
    return isHasOptionAll ? [optionAll].concat(resultOptions) : resultOptions;
  }, [dataList, initialValue]);

  return (
    <div className="position-relative">
      <Select
        options={options}
        value={initialValue}
        name={name}
        isDisabled={disabled || readOnly}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{placeholder || ""}</div>}
        loadingMessage={() => <div>{t("Loading")}...</div>}
        noOptionsMessage={() => <div>{t("No Options")}</div>}
        className={className}
        classNamePrefix={classNamePrefix}
        isClearable={false}
        readOnly={readOnly}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
      />
      {labelTop && initialValue?.value && (
        <label
          style={{
            position: "absolute",
            left: "5px",
            top: "0px",
            marginBottom: "0px",
            transform: "translateY(-50%)",
            padding: `0px 5px`,
            color: "#c2c2c2",
            fontSize: "12px",
            fontWeight: "400",
            borderRadius: "3px",
            backgroundColor: disabled
              ? "hsl(0, 0%, 95%)"
              : "var(--vz-secondary-bg)",
          }}
        >
          {labelTop}
        </label>
      )}
    </div>
  );
};

export default DropdownOption;
