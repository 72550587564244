import { APIClient } from "./axiosClient";
import { QueryParams } from "components/Hooks/useQueryParams";
const api = new APIClient();

const path = "/cmshub";
const cmsHubMembershipApi = {
  cmshubMembership(params: QueryParams) {
    return api.get(`${path}/payments`, params);
  },
  cmshubMembershipSumary(params: QueryParams) {
    return api.get(`${path}/payments/summary`, params);
  },
  cmshubCompanyPlan(params: QueryParams) {
    return api.get(`${path}/company-plans`, params);
  },
  cmshubSummaryCompanyPlan(params: QueryParams) {
    return api.get(`${path}/company-plans/summary`, params);
  },
  cmshubCompanyPlanHistory(company_id: number) {
    return api.get(`${path}/company-plans/${company_id}/histories/payment`);
  },
  cmshubCompanyPlanTrafficHistory(company_id: number) {
    return api.get(`${path}/company-plans/${company_id}/histories/traffic`);
  },
  cmshubCompanyExceededUsageCount() {
    return api.get(`${path}/company-plans/exceeded-usages/count`);
  },
  cmshubCompanyExceededUsages(params: QueryParams) {
    return api.get(`${path}/company-plans/exceeded-usages`, params);
  },
  getDetailCompany(id: number) {
    return api.get(`${path}/companies/${id}`);
  },
  deactivateMembership(company_id: number) {
    return api.post(`${path}/company-plans/${company_id}/deactivate`, {});
  },
  updateMembership(company_id: number, params: any) {
    return api.put(`${path}/company-plans/${company_id}/update`, params);
  },
  updatePaymentTime(
    company_id: number,
    params: {
      hours: number;
      minutes: number;
    }
  ) {
    return api.post(
      `${path}/company-plans/exceeded-usages/payment-time/${company_id}`,
      params
    );
  },
  deletePaymentTime(company_id: number) {
    return api.delete(`${path}/company-plans/exceeded-usages/${company_id}`);
  },
  companiesCreate(params: any) {
    return api.post(`/companies/create`, params);
  },
  changeSpecialStatus(company_id: number,status: number) {
    return api.post(`${path}/company-plans/${company_id}/change-special-status`, {status});
  },
  cmshubMembershipPonts(params: QueryParams) {
    return api.get(`/points/histories`, params);
  },
  cmshubCancelPayment(paymentId: number) {
    return api.post(`/cmshub/payments/cancel/${paymentId}`, {});
  },
  sendEnterPriseFee(companyId: number,params: any) {
    return api.post(`/cmshub/company-plans/${companyId}/send-enterprise-fee`, params);
  },
};

export default cmsHubMembershipApi;
